.root {
  background-color: var(--backgroundGray);
  padding-bottom: 40px;
  min-height: 100%;
  width: 100%;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
  padding: 10px;
  cursor: pointer;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
  max-width: 200px;
}

.logoutIcon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.logoutText {
  font-size: 16px;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
  height: 30px;
  line-height: 35px;
  margin-left: 24px;
}

.companyWrapper {
  margin-top: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.company {
  display: flex;
  align-items: center;
  position: relative;
  padding: 18px 35px 15px 14px;
  border-radius: 16px;
  background-color: var(--white);
  line-height: 21px;
  cursor: pointer;
  max-width: 500px;
  margin: 0 auto 15px;
}

.companyLogo {
  width: 75px;
}

.companyLogoText {
  width: 50px;
  height: 50px;
  padding: 16px 0;
  background: var(--textColor);
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: var(--white);
  font-weight: var(--fontWeightMedium);
}

.companyText {
  display: flex;
  flex-direction: column;
}

.companyName {
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  font-size: 20px;
  width: 100%;
}

.title {
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  text-align: center;
}

.companySelect {
  width: 500px;
  margin: 10px 0;
  padding: 0 10px;
  height: 40px;
  border-radius: 25px;
  text-align: center;
}

.companySelect select {
  font-size: 16px;
}

.ctaButton {
  width: 200px;
  margin: 20px 0;
}

@media only screen and (max-width: 1024px) {
  .company {
    margin: 15px;
  }

  .companyLogo {
    width: 55px;
  }

  .companyName {
    font-size: 16px;
  }

  .companyWrapper {
    margin-top: 46px;
  }

  .companyLogoText {
    width: 35px;
    height: 35px;
    padding: 10px 0;
    font-size: 16px;
  }
}
