.root {
  text-align: center;
  height: calc(100vh / var(--vpUnitsFactor, 1));
  width: 100%;
  padding-bottom: 0;
  background: var(--backgroundGray);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.messageText {
  white-space: pre-line;
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.navItemWrapper {
  display: flex;
  align-items: center;
  margin: 16px 33px 16px 0;
  padding: 10px 5px 10px 48px;
  cursor: pointer;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
}

.navItemText {
  font-size: 16px;
  color: inherit;
  font-weight: var(--fontWeightRegular);
  height: 30px;
  line-height: 35px;
  margin-left: 24px;
}

@media only screen and (max-width: 1024px) {
  .navItemWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 6px 0;
    cursor: pointer;
    color: var(--textColor);
  }

  .navItemText {
    margin-left: 0;
    margin-top: 8px;
    text-transform: uppercase;
    height: 10px;
    font-size: 8px;
    font-weight: var(--fontWeightRegular);
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: inherit;
  }

  .signOutButton {
    display: none;
  }
}
