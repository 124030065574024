.root {
  display: flex;
}

.container {
  flex-wrap: wrap;
  margin: 20px 10px;
  padding: 40px 25px;
  background: var(--backgroundGray);
  border: 1px solid var(--backgroundHeader);
  border-radius: 16px;
}

.container:first-child {
  margin-left: 0;
  flex: 1 1;
}

.container:last-child {
  margin-right: 0;
}

.flexContainer {
  display: flex;
  width: 100%;
}

.userProfileContainer {
  margin: 0 auto 20px auto;
}

.innerContainer {
  flex: 1 1 250px;
}

.accountName {
  font-size: 20px;
  text-align: center;
}

.avatar {
  margin: 10px auto;
  width: 32px;
  height: 32px;
  line-height: 35px;
  text-align: center;
  color: var(--white);
  background-color: var(--primaryBlue);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(107, 107, 107, 0.5), 0 2px 5px rgba(41, 41, 41, 0.5);
}

.dividerContainer {
  margin: 0 20px;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.heading:not(:first-child) {
  margin-top: 40px;
}

.text {
  margin: 5px 0;
  font-size: 16px;
  color: var(--textColor);
}

.bold {
  font-weight: 700;
  color: var(--white);
}

.nowrap {
  white-space: nowrap;
}

.breakwrap {
  word-wrap: break-word;
}

.emailLink,
.link {
  color: var(--goodW);
}

.emailLink:hover {
  cursor: pointer;
}

.onlyReportsPartnerId {
  color: var(--marigold);
}

.override.accordionItemPadding {
  padding: 8px 0;
}

.override.override.tableCellCustomization > div {
  min-height: 22px;
}

.override.override.rowHover:hover {
  background-color: var(--portalHoverBackground);
}

@media only screen and (max-width: 1600px) {
  .innerContainer {
    width: auto;
    max-width: none;
  }

  .flexContainer {
    flex-direction: column;
  }

  .dividerContainer {
    display: none;
  }

  .innerContainer:not(:first-child) .heading:first-child {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1368px) {
  .root {
    display: block;
  }

  .container {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .dividerContainer {
    display: block;
  }
}
