.root {
  position: absolute;
  border-radius: 10px;
  width: 634px;
  max-width: calc(100% - 30px);
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
}

.darkenBackground {
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  position: absolute;
  background-color: var(--darkenBackground);
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.title,
.content {
  text-align: center;
  max-width: 390px;
  color: var(--textColor);
}

.title {
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  line-height: 29px;
  margin: 76px auto 19px auto;
}

.content {
  font-size: 16px;
  line-height: 1.38;
  margin: 0 auto 24px auto;
  white-space: pre-line;
}

.buttonWrapper {
  margin: 0 auto 43px auto;
  max-width: 306px;
  height: 56px;
}

.actionButton {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .closeButton {
    right: 11px;
    top: 11px;
  }

  .title {
    max-width: 270px;
    margin: 65px auto 21px auto;
  }

  .content {
    max-width: 284px;
    margin: 0 auto 20px auto;
  }

  .buttonWrapper {
    margin: 0 auto 39px auto;
    max-width: 197px;
  }
}
