.root {
  padding: 50px 80px;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

.section {
  margin-bottom: 80px;
}

.appTiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 80px;
}

.appTiles > * {
  max-width: 380px;
}

.networkActionList {
  display: inline-block;
}

.networkActionList > * {
  flex: 1 1 300px;
  max-width: 380px;
}

.listCheckbox {
  display: none;
}

.listCheckLabel {
  position: relative;
  top: -4px;
}

.button {
  height: 50px;
  width: 210px;
  padding: 15px 0 16px;
}

.header {
  margin-bottom: 10px;
}

.headerTitle {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  width: 66%;
}

.headerMenu {
  width: 33%;
  text-align: right;
}

.noAppsText {
  margin-top: 80px;
  font-size: 18px;
  color: var(--textColor);
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.noAppsTextDesc {
  margin-top: 10px;
  font-size: 12px;
  color: var(--labelColor);
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .root {
    padding: 25px 15px;
  }

  .appTiles {
    display: flex;
    justify-content: center;
  }
}
