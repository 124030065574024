.root {
  margin-top: -13px;
}

.description {
  text-align: left;
  font-size: 12px;
  line-height: 1.33;
  color: var(--labelColor);
}
