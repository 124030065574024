.ConfigurationCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  padding: 10px;
  z-index: 1;
  color: white;
  box-sizing: border-box;
  background-color: var(--backgroundGray);
  border-bottom: 1px solid var(--backgroundHeader);
}

.ConfigurationCardActionsContainer {
  display: flex;
  align-items: center;
}

.ConfigurationCardAction {
  margin-left: 10px;
}

.ConfigurationCardContent {
  padding: 10px;
}

.ConfigurationCardContentLoading {
  padding: 0;
}

.CardLoadingHeight {
  height: 61px;
}

.showNewlines {
  margin-right: auto;
  white-space: pre-line;
}

.cardButtonWidth {
  width: 150px;
}

.personPlumeIcon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.personPlumeIcon path {
  fill: white;
}

@media only screen and (max-width: 500px) {
  .ConfigurationCardHeader {
    flex-direction: column;
    height: unset;
    align-items: flex-start;
  }

  .ConfigurationCardAction {
    margin-left: 0;
  }

  .CardLoadingHeight {
    height: unset;
  }
}
