.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  pointer-events: all;
  padding: 40px 40px 20px 40px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
  min-width: 584px;
}

.labelText {
  margin-bottom: 10px;
  padding: 5px 10px;
  width: 100%;
  color: var(--labelColor);
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  text-align: center;
  white-space: pre-line;
}

.tooltipText {
  display: block;
  white-space: pre;
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
}

.input input {
  padding: 24px 0 0;
}

.inputDisabled input {
  color: var(--labelColor);
}

.input:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.formInputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.formInput {
  flex: 0 calc(50% - 5px);
}

.releaseNotesFormInput {
  flex: 0 100%;
  width: 500px;
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.rncServerError {
  margin: -10px 10px 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.existingContact {
  padding: 0 10px;
  width: 100%;
}

.usersWrapper {
  min-width: 584px;
  height: 200px;
  margin-top: 20px;
  overflow-y: scroll;
}

.userWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
}

.userWrapperSelecting:hover {
  background-color: var(--hoverItem);
}

.userWrapperSelected {
  background-color: var(--itemSelected);
}

.userInfoWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.userInfoColumn {
  flex: 1 1 80%;
}

.logo {
  width: 65px;
}

.logoCircle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
}

.logoText {
  font-size: 16px;
  color: var(--white);
  line-height: 36px;
}

.mHeaderName {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .subTitle {
    min-width: calc(80vw / var(--vpUnitsFactor, 1));
    margin: 12px 10px 30px 10px;
  }

  .input {
    height: 60px;
  }

  .formInputs {
    margin-bottom: 30px;
  }

  .rncServerError {
    margin: -30px 10px 0;
  }

  .formInput,
  .releaseNotesFormInput {
    flex: 0 100%;
  }

  .usersWrapper {
    min-width: 0;
  }
}

@media only screen and (max-width: 454px) {
  .userInfoColumn {
    flex: 1 1 40%;
  }
}
