.trial {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;
  color: var(--primaryBlue);
}

.center {
  justify-content: center;
}

.accountStatus {
  color: var(--labelColor);
}

.dot circle {
  fill: #999c9e;
  stroke: #999c9e;
}

.trialWarning {
  color: var(--warning);
}

.active {
  color: var(--sucess);
}

.company {
  font-size: 12px;
  color: var(--labelColor);
  text-align: right;
}
