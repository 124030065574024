.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 94px;
  background: var(--backgroundHeaderOverlay);
  /* stylelint-disable-next-line */
  background: linear-gradient(
    90deg,
    var(--backgroundHeaderOverlay) 0%,
    var(--backgroundHeaderOverlay) 270px,
    var(--backgroundHeader) 330px
  );
  z-index: 1;
}

.logo {
  display: flex;
  justify-content: left;
  width: 184px;
  height: 50px;
  margin: 0 50px;
  background: url('../../../../assets/svg/inline/logo-header.svg') no-repeat left;
  background-size: 184px 50px;
}

.input {
  display: block;
  margin: 0 30px;
  padding: 10px 25px;
  height: 45px;
  width: 579px;
  color: var(--textColor);
  background-color: var(--backgroundGray);
  border: 0 solid var(--primaryGray);
  border-radius: 25px;
  font-size: 16px;
  font-weight: var(--fontWightRegular);
  font-family: var(--defaultFontFamily);
}

.input:focus {
  outline: none;
}

.logoInputWrapper {
  display: flex;
  align-items: center;
}

.accountWrapper {
  display: flex;
}

.cartAccountWrapper {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.cart {
  display: flex;
  justify-content: center;
  margin: 0 20px;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer;
}

.avatar {
  margin: 0 10px;
  width: 32px;
  height: 32px;
  line-height: 35px;
  text-align: center;
  color: var(--white);
  background-color: var(--primaryBlue);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(107, 107, 107, 0.5), 0 2px 5px rgba(41, 41, 41, 0.5);
}

.accountStatus {
  color: var(--labelColor);
}

.trial {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;
  color: var(--primaryBlue);
}

.company {
  font-size: 12px;
  color: var(--labelColor);
  text-align: right;
}

.trialWarning {
  color: var(--warning);
}

.live {
  color: var(--sucess);
}

.dot circle {
  fill: #999c9e;
  stroke: #999c9e;
}

.textWrapper {
  display: inline-block;
}

.accountName {
  text-align: right;
  color: var(--labelColor);
}

.override.iconSize svg {
  height: 24px;
}

.override.dropdownMargins {
  margin-left: auto;
  margin-right: 20px;
}

.terminatedTrialTextModifications :global(.DropdownItem__button-label):global(.text) {
  color: var(--warning);
}

.spinnerModifications.spinnerModifications {
  transform: scale(0.25);
  height: 12px;
  width: 12px;
}

@media only screen and (max-width: 1024px) {
  .headerWrapper {
    height: 57px;
    background: var(--backgroundHeader);
  }

  .logo {
    height: 33px;
    max-width: 124px;
    margin: 0 25px;
    background: url('~assets/svg/inline/logo-header.svg') no-repeat left;
    background-size: 124px 33px;
  }

  .cart {
    margin: 0 20px;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }

  .avatar {
    margin: 0 20px;
  }

  .input {
    display: none;
  }

  .override.dropdownMargins {
    margin-left: 0;
    margin-right: auto;
  }

  .override.dropdownList {
    width: max-content;
    max-width: calc(100vw / var(--vpUnitsFactor, 1) - 80px);
  }
}
