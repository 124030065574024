.root {
  position: absolute;
  border-radius: 10px;
  width: 634px;
  max-width: calc(100% - 30px);
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
}

.banner {
  background-image: url('../../../../assets/svg/rebranding-banner.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 210px;
  height: 100px;
  margin: 0 auto;
  margin-top: 76px;
}

.title {
  color: var(--textColor);
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  line-height: 29px;
  margin: 38px auto 19px auto;
}

.subTitle {
  font-size: 14px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 24px;
  font-weight: var(--fontWeightMedium);
}

.list {
  max-width: 490px;
  margin: 0 auto;
  margin-top: 19px;
}

.list li {
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  color: var(--primaryGray);
}

.content {
  font-size: 16px;
  line-height: 20px;
  margin: 24px auto;
}

.title,
.content {
  text-align: center;
  max-width: 490px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.serverError {
  text-align: center;
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
  margin-bottom: 12px;
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.checkboxWrapperText {
  font-size: 14px;
  line-height: 22px;
}

.buttonWrapper {
  margin: 0 auto 43px auto;
  max-width: 306px;
  height: 56px;
}

.actionButton {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .subTitle {
    margin: 12px 10px 30px 10px;
  }

  .closeButton {
    right: 11px;
    top: 11px;
  }

  .title {
    max-width: 270px;
    margin: 65px auto 21px auto;
  }

  .content {
    max-width: 284px;
    margin: 0 auto 20px auto;
  }

  .buttonWrapper {
    margin: 0 auto 39px auto;
    max-width: 197px;
  }
}
