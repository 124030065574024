.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.barChartAxis {
  stroke: #c6c7c9;
  fill: none;
  stroke-width: 2px;
}

.title {
  font-size: 12px;
  color: var(--labelColor);
}

.title svg {
  width: 14px;
  height: 14px;
  vertical-align: sub;
}

.titleMonth {
  float: right;
}

.axisLabel {
  fill: #c6c7c9;
  font-size: 10px;
  text-transform: uppercase;
  text-anchor: middle;
}

.firstChartBar {
  fill: #5a5b5f;
  cursor: pointer;
}

.firstChartBarHidden {
  visibility: hidden;
}

.firstChartBar:hover {
  fill: #a9a9b7;
}

.barChartSelected,
.barChartSelected:hover {
  fill: url(#selectedGradient);
  cursor: pointer;
}

.secondChartBar {
  fill: #da197e;
}

.displayNone {
  display: none;
}

.displayHidden {
  visibility: hidden;
}

.displayInline {
  display: inline;
}

.toolTip {
  position: absolute;
  font-size: 10px;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.34);
  background-color: #1d2120;
  border: 1px solid #8f9397;
  padding: 5px 5px 3px 4px;
  margin-left: 2px;
  text-transform: uppercase;
}
