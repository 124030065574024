.progressBarContainer {
  --progressBarHeight: 20px;
  --progressBarFullWidth: 80%;

  display: flex;
  justify-content: center;
  height: var(--progressBarHeight);
}

.progressBar {
  position: absolute;
  height: var(--progressBarHeight);
  border-radius: 10px;
}

.blendWhite {
  background: white;
  width: var(--progressBarFullWidth);
  z-index: 1;
}

.blendBlack {
  background: black;
  width: calc(var(--progressBarWidth) * var(--progressBarFullWidth));
  z-index: 2;
  left: calc(calc(100% - var(--progressBarFullWidth)) / 2);
}

.progressBarText {
  position: absolute;
  mix-blend-mode: difference;
  color: white;
  z-index: 3;
}

.blendBlue {
  background-color: var(--primaryBlue);
  mix-blend-mode: screen;
  width: var(--progressBarFullWidth);
  z-index: 4;
}

.progressLoaderOverlay {
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--backgroundGray);
}

.subTitle {
  position: absolute;
  transform: translate(0, -30px);
  font-weight: var(--fontWeightMedium);
}
