.root {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: 2;
}

.rootContent {
  width: 420px;
  right: 14px;
  bottom: 14px;
  padding: 18px;
  border-radius: 20px;
  max-width: calc(100% - 30px);
  background: var(--primaryBlue);
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.31);
  margin: auto;
}

.title,
.content {
  max-width: 465px;
}

.title {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  color: var(--textColor);
  padding: 8px 18px;
}

.content {
  font-size: 16px;
  color: var(--textColor);
  line-height: 1.38;
  padding: 8px 18px;
}

.partnerLink {
  color: var(--textColor);
  cursor: pointer;
}

.buttonWrapper {
  padding: 10px 18px;
}

.buttonWrapper button {
  color: var(--textColor);
  background-color: transparent;
  text-align: right;
  margin-bottom: 0;
  height: auto;
}

.actionButton {
  display: block;
}

.closeButton {
  position: relative;
  cursor: pointer;
  float: right;
}

@media only screen and (max-width: 1024px) {
  .title {
    max-width: 270px;
  }

  .content {
    max-width: 284px;
    margin: 0 auto 20px auto;
  }

  .buttonWrapper {
    margin: 0 auto 39px auto;
    max-width: 197px;
  }
}
