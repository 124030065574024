.root {
  overflow: hidden;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: max-height 0.4s ease-in, visibility 0.4s, opacity 0.4s;
}

.collapse {
  visibility: hidden;
  opacity: 0;
  transition: max-height 0.4s ease-out, visibility 0.4s, opacity 0.4s;
}
