.modalRoot {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: auto;
  z-index: 2;
}

.modalLayer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.7);
}

.root {
  position: absolute;
  border-radius: 10px;
  width: 634px;
  max-width: calc(100% - 30px);
  background: var(--backgroundGray);
  top: calc(50% - 90px);
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
}

.title,
.content {
  text-align: center;
  max-width: 390px;
  color: var(--textColor);
}

.title {
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  line-height: 29px;
  margin: 76px auto 19px auto;
}

.content {
  font-size: 16px;
  line-height: 1.38;
  margin: 0 auto 24px auto;
}

.buttonWrapper {
  margin: 0 auto 43px auto;
  max-width: 306px;
  height: 56px;
}

.actionButton {
  display: block;
}

.partnerLink {
  color: var(--primaryBlue);
  cursor: pointer;
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.navItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 33px 16px 0;
  padding: 0 5px 10px 48px;
  cursor: pointer;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
}

.navItemText {
  font-size: 16px;
  color: inherit;
  font-weight: var(--fontWeightRegular);
  height: 30px;
  line-height: 35px;
  margin-left: 24px;
}

@media only screen and (max-width: 1024px) {
  .modalRoot {
    top: 0;
  }

  .root {
    top: 50%;
  }

  .title {
    max-width: 270px;
    margin: 65px auto 21px auto;
  }

  .content {
    max-width: 284px;
    margin: 0 auto 20px auto;
  }

  .buttonWrapper {
    margin: 0 auto 39px auto;
    max-width: 197px;
  }
}
