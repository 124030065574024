.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  position: absolute;
}

.header {
  display: flex;
  height: 94px;
  justify-content: center;
  background: url("~assets/svg/logo-white.svg") no-repeat center;
  background-size: 142px 39px;
  background-color: var(--backgroundHeader);
}

.content {
  flex-grow: 1;
  background: var(--backgroundGray);
  padding: 106px 15px 141px;
}

.contentWrapper {
  margin: 0 auto;
  max-width: 360px;
}

.subTitle {
  font-size: 13px;
  line-height: 1.3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--textColor);
}

.title {
  font-size: 50px;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 54px;
  text-align: center;
}

.titleWithDescription {
  margin-bottom: 32px;
}

.description {
  margin-bottom: 32px;
  text-align: center;
  line-height: 1.38;
}

@media only screen and (max-width: 1024px) {
  .header {
    height: 56px;
    background-size: 127px 34px;
  }

  .content {
    padding: 34px 15px 34px;
  }

  .title {
    font-size: 44px;
    margin-bottom: 44px;
  }

  .titleWithDescription {
    margin-bottom: 30px;
  }
}
