.root {
  position: relative;
  margin-bottom: 20px;
  text-align: left;
}

.label {
  color: var(--textColor);
  font-size: 12px;
  position: absolute;
  top: 5px;
  left: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 31px;
  line-height: 2.17;
  font-weight: var(--fontWeightNormal);
}

.tooltipInfoText {
  white-space: pre-line;
  width: 280px;
  max-height: 280px;
  padding: 5%;
  overflow-y: auto;
  scrollbar-width: thin;
}

.tooltipInfoText::-webkit-scrollbar {
  width: 7px;
}

.tooltipInfoText::-webkit-scrollbar-track {
  background: var(--backgroundGray);
}

.tooltipInfoText::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
}

.tooltipInfoText::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbarThumbHover);
}

.infoTooltipContainter {
  position: absolute;
  cursor: pointer;
  right: 5px;
}

.infoButton {
  width: 40px;
  height: 40px;
  padding: 5px;
  display: inline-block;
  fill: var(--textColor);
  background: var(--selectBackground);
  border-radius: 15%;
  transition: 0.3s;
}

.infoButtonSmall {
  width: 30px;
  height: 30px;
}

.infoButton:hover {
  opacity: 0.7;
}

.input {
  color: var(--textColor);
  border: 0 solid var(--primaryGray);
  border-bottom-width: 2px;
  display: block;
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  font-family: var(--defaultFontFamily);
  height: 60px;
  transition: 0.2s ease border-color;
  padding: 24px 9px 0;
  width: 100%;
  background-color: var(--inputBackground);
  caret-color: var(--white);
  word-break: normal;
}

.input:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.invalid .input {
  border-color: var(--error);
}

.valid .input {
  border-color: var(--sucess);
}

.error {
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.input:disabled {
  cursor: default;
  pointer-events: none;
}
