.root {
  display: block;
}

.radio {
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding-left: 20px;
  border: 1px solid var(--primaryGray);
  background-color: var(--yesNoToggleBackground);
  border-radius: 30px;
  position: relative;
  float: left;
  width: 100%;
  height: 60px;
}

.disabled {
  background-color: var(--yesNoToggleDisabled);
}

.disabled label {
  cursor: default !important;
}

.radio input[type=radio] {
  position: absolute;
  visibility: hidden;
}

.radio label {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-left: -35px;
  padding-left: 45px;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  cursor: pointer;
}

.radio .check {
  border: 1px solid var(--borderColor);
  border-radius: 50%;
  height: 25px;
  width: 26px;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  background-color: var(--white);
}

input[type=radio]:checked ~ .check {
  border: 7px solid var(--primaryBlue);
  height: 25px;
  width: 26px;
}
