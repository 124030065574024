.modalRoot {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
}

.modalLayer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.7);
}

.modalLayerWithChildren {
  display: block;
}
