/** Sentry */

:global body .sentry-error-embed {
  border-radius: 10px;
  background: var(--backgroundGray);
  padding: 60px 60px 30px 60px;
  box-shadow: none;
  border: none;
}

:global body .sentry-error-embed * {
  font-family: var(--defaultFontFamily);
}

:global body .sentry-error-embed h2 {
  color: var(--textColor);
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  line-height: 29px;
}

:global body .sentry-error-embed header {
  border-bottom: none;
}

:global body .sentry-error-embed header p {
  color: var(--labelColor);
}

:global body .sentry-error-embed .form-submit button.btn {
  background: var(--primaryBlue);
  border: 0;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  height: 44px;
  margin-bottom: 20px;
  padding: 0 24px;
  border-radius: 25px;
  display: inline-block;
}

:global body .sentry-error-embed .form-submit .close {
  display: inline-block;
  width: auto;
  height: 44px;
  padding: 12px 36px;
  font-size: 18px;
  color: var(--primaryBlue);
  border: solid 2px var(--primaryBlue);
  border-radius: 24px;
  cursor: pointer;
  font-family: var(--defaultFontFamily);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
}

:global body .sentry-error-embed .form-submit .btn:hover {
  background: var(--primaryBlue);
  outline: 0;
}

:global body .sentry-error-embed .form-field input,
:global body .sentry-error-embed .form-field textarea {
  color: var(--textColor);
  background: transparent;
  border: 0 solid var(--primaryGray);
  border-bottom-width: 2px;
  display: block;
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  -webkit-transition: 0.2s ease border-color;
  transition: 0.2s ease border-color;
  padding: 9px 0;
  width: 100%;
}

:global body .sentry-error-embed .form-field input:focus,
:global body .sentry-error-embed .form-field textarea:focus {
  border-color: var(--primaryBlue);
}

:global body .sentry-error-embed .form-field input::placeholder,
:global body .sentry-error-embed .form-field textarea::placeholder {
  color: var(--textColor);
}

:global body .sentry-error-embed .form-field label {
  color: var(--labelColor);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 31px;
  line-height: 2.17;
  font-weight: var(--fontWeightNormal);
}

:global body .sentry-error-embed .form-submit .powered-by {
  display: none;
}
