.root {
  width: 100%;
}

.label {
  color: var(--labelColor);
  font-size: 12px;
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 5px 9px 0;
  height: 31px;
  line-height: 2.17;
  font-weight: var(--fontWeightMedium);
}

.root textarea {
  color: var(--textColor);
  border: 0 solid #f2f3f5;
  height: 240px;
  border-bottom-width: 2px;
  display: block;
  background-color: var(--selectBackground);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  font-family: var(--defaultFontFamily);
  transition: 0.2s ease border-color;
  padding: 30px 9px 0;
  width: 100%;
  line-height: 1.2;
  /* stylelint-disable */
  resize: none;
  /* stylelint-enable */
  min-width: 100%;
  min-height: 280px;
}

textarea:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.invalid textarea {
  border-color: var(--error);
}

.valid textarea {
  border-color: var(--sucess);
}

.error {
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}
