.hmsInputs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hmsInput {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  max-width: 100px;
}

.hmsInput input {
  padding: 24px 0 0;
}

.hmsInputDisabled input {
  color: var(--labelColor);
}

.hmsInput:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.hmsButton {
  margin-bottom: 0;
  padding: 0 16px;
  white-space: pre;
}
