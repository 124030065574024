.root {
  position: absolute;
  border-radius: 10px;
  width: 634px;
  max-width: calc(100% - 30px);
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 60px 30px 60px;
  pointer-events: all;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.title,
.subTitle {
  text-align: center;
  max-width: 420px;
  color: var(--textColor);
}

.title {
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  line-height: 29px;
  margin: 24px auto;
}

.subTitle {
  font-size: 16px;
  line-height: 1.38;
  margin: 0 auto 24px auto;
}

.cancelBtn,
.deleteBtn {
  text-align: center;
}

.deleteBtn button {
  padding: 12px 48px;
  font-size: 18px;
}

.cancelBtn button {
  margin-bottom: 0;
  background: none;
  color: var(--primaryBlue);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
}
