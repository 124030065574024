.root {
  background: var(--primaryBlue);
  border: 0;
  border-radius: 28px;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  height: 44px;
  font-family: var(--defaultFontFamily);
  margin-bottom: 20px;
}

.root:focus {
  outline: 0;
}

.fullWidth {
  width: 100%;
}

.root:disabled {
  background: var(--borderColor);
  cursor: default;
}
