.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  padding: 60px 60px 30px 60px;
  max-width: 420px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.checkboxModifications.checkboxModifications.checkboxModifications {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  cursor: default;
}

.checkboxModifications.checkboxModifications.checkboxModifications label {
  user-select: none;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  max-width: 100px;
}

.marginLeft {
  margin-left: 260px;
}

.deleteBtn {
  text-align: center;
}

.deleteBtn button {
  margin-bottom: 0;
  background: none;
  color: var(--primaryBlue);
  font-size: 16px;
  padding: 12px;
  font-weight: var(--fontWeightRegular);
}

.modalHidden {
  visibility: hidden;
}

.btnLabel {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.toolsWrapper {
  margin: 0 10px;
}

.toolText {
  margin-top: -10px;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--fontWeightMedium);
}

.toolsCb {
  margin-bottom: 10px;
  display: inline-flex;
}

.toolsCbDisabled span {
  border: 1px solid var(--labelColor);
}

.toolsText {
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 22px;
  color: var(--labelColor);
}

.roleSelect {
  display: inline-block;
  width: calc(50% - 5px);
}

.roleSelectSingle {
  margin-top: 10px;
  padding-left: 5px;
  display: inline-block;
  width: calc(50% - 5px);
}

.formInputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.dhmInputs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formInput {
  flex: 0 calc(50% - 5px);
}

.portalLanguageSelect {
  display: inline-block;
  width: 100%;
}

.portalLanguageFormInput {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 0 10px;
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  background-color: #f2f3f5;
  text-align: center;
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.configItem {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning {
  margin-left: 10px;
  color: var(--marigold);
  font-size: 12px;
  font-weight: var(--fontWeightNormal);
  text-align: center;
  margin-bottom: 10px;
}

.label {
  margin: 0 0 10px 10px;
  color: var(--labelColor);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--fontWeightNormal);
  text-align: center;
}

.regenerateLabel {
  margin: 0 0 10px 10px;
  color: var(--labelColor);
  font-size: 12px;
  font-weight: var(--fontWeightNormal);
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .btnLabel {
    padding: 10px;
    font-weight: var(--fontWeightNormal);
    color: var(--labelColor);
  }

  .subTitle {
    margin: 12px 50px 30px 10px;
  }

  .input {
    height: 60px;
  }

  .roleSelect {
    display: inline-block;
    width: 100%;
  }

  .formInputs {
    margin-bottom: 30px;
  }

  .formInput {
    flex: 0 100%;
  }

  .toolsCb {
    display: inline-flex;
    width: 50%;
  }
}
