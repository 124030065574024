.container {
  width: 100%;
  min-height: 200px;
}

.row {
  position: relative;
  display: flex;
  margin: 0;
}

.dataContainer {
  display: flex;
  flex-wrap: nowrap;
}

.col {
  width: 33.33%;
  min-width: 250px;
  padding: 16px;
}

.colSpan2 {
  width: 100%;
}

.paperContentWrapper {
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stats {
  width: 45%;
}

.icon {
  font-size: 12px;
  color: var(--labelColor);
  display: flex;
  align-items: center;
}

.statsTitle {
  padding-top: 2px;
  font-size: 12px;
  line-height: 21px;
  color: #939597;
}

.block {
  margin-top: 5px;
  display: block;
  text-align: left;
  width: 100%;
}

.value {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  margin-left: 15px;
}

.graphWrapper {
  width: 100%;
  height: 125px;
  min-height: 100px;
  padding-top: 20px;
}

.disclaimer {
  line-height: 1.5;
  margin-top: 16px;
  color: var(--textColor);
}

.override.override.labelIncomplete {
  color: var(--marigold);
}

.override.override.labelGraphOnline {
  color: var(--sucess);
  font-size: 12px;
}

.override.override.labelGraphOffline {
  font-size: 12px;
}

@media only screen and (max-width: 1280px) {
  .col {
    width: 100%;
  }

  .dataContainer {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .row {
    display: flex;
    flex-direction: column;
  }

  .col,
  .colSpan2 {
    width: 100%;
    min-width: 200px;
  }

  .wrap {
    flex-direction: column;
  }

  .wrap .stats:nth-child(1) {
    padding-bottom: 15px;
  }

  .wrap .paperContentWrapper {
    width: 100%;
  }

  .filler {
    display: none;
  }

  .account {
    margin: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
}
