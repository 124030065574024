.root {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.title {
  flex: 0 100%;
  margin: 30px 10px 10px 20px;
  font-size: 20px;
  color: var(--textColor);
}

.formInputRadio {
  flex: 0 calc(50% - 15px);
  margin: 0 5px;
  position: relative;
}
