.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--backgroundGray);
  height: calc(100vh / var(--vpUnitsFactor, 1));
}

.errorHeading {
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  color: var(--textColor);
  margin: 0 auto;
}

.separator {
  height: 2px;
  margin: 32px auto 0;
  background: #6269ff;
  max-width: 338px;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBtn {
  margin: 31px auto 0;
  margin-top: 23px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 14.5036px;
  line-height: 22px;
  padding: 8px 24px 6px;
  max-width: 206px;
  min-height: 40px;
}

@media screen and (max-width: 767px) {
  .root {
    padding-bottom: 120px;
  }

  .heading {
    margin: 120px auto 0;
  }
}
