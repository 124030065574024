.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.subTitle {
  margin: 30px 10px;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

.videoPlayer {
  display: flex;
  background-color: var(--backgroundGray);
  padding: 10px;
  border-radius: 12px;
}

.video {
  width: 530px;
  height: 300px;
}

.videoEntry {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}

.videoEntry:hover {
  background-color: var(--hoverItem);
}

.videoThumbnail {
  height: 90px;
  width: 120px;
}

.videoEntries {
  width: 400px;
  height: 300px;
  overflow-y: scroll;
  margin-left: 20px;
  scrollbar-width: thin;
}

.videoTitle {
  margin-left: 5px;
}

.infoCardsWrapper {
  display: flex;
}

.jobAidContainer {
  position: relative;
  margin: 20px 0;
  padding: 20px;
  background: var(--backgroundGraySection);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  width: 350px;
}

.jobAidContainer + .jobAidContainer {
  margin-left: 20px;
}

.jobAidTitle {
  margin-bottom: 8px;
  padding-right: 70px;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
}

.jobAidLink {
  line-height: 24px;
  font-size: 16px;
  color: var(--textColor);
}

@media only screen and (min-width: 1025px) {
  .video {
    width: calc(40vw / var(--vpUnitsFactor, 1));
    height: calc(23vw / var(--vpUnitsFactor, 1));
  }

  .videoEntries {
    width: calc(28vw / var(--vpUnitsFactor, 1));
    height: calc(23vw / var(--vpUnitsFactor, 1));
  }

  .subTitle {
    font-size: calc(1vw / var(--vpUnitsFactor, 1));
  }

  .videoEntries::-webkit-scrollbar {
    width: 7px;
  }

  .videoEntries::-webkit-scrollbar-track {
    background: var(--backgroundGray);
  }

  .videoEntries::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumb);
  }

  .videoEntries::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbarThumbHover);
  }
}

@media only screen and (min-width: 1600px) {
  .video {
    width: calc(0.6 * (100vw / var(--vpUnitsFactor, 1) - 300px));
    height: calc(0.34 * (100vw / var(--vpUnitsFactor, 1) - 300px));
  }

  .videoEntries {
    width: calc(0.35 * (100vw / var(--vpUnitsFactor, 1) - 300px));
    height: calc(0.34 * (100vw / var(--vpUnitsFactor, 1) - 300px));
  }

  .videoThumbnail {
    width: calc(8vw / var(--vpUnitsFactor, 1));
    height: calc(6vw / var(--vpUnitsFactor, 1));
  }

  .videoTitle {
    margin-left: 10px;
    font-size: calc(1vw / var(--vpUnitsFactor, 1));
  }
}

@supports (zoom: 2) {
  @media only screen and (min-width: 2560px) {
    .root {
      padding: 20px 10px;
      justify-content: initial;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .root {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 600px) {
  .videoPlayer {
    flex-direction: column;
  }

  .videoEntries {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .video {
    width: 100%;
    height: calc(50vw / var(--vpUnitsFactor, 1));
  }

  .subTitle {
    margin: 30px 10px 0 10px;
  }
}
