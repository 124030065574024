.editApplicationTeamRoot {
  width: 635px;
  padding: 25px;
}

.addTeamRoot {
  padding: 40px 40px 20px 40px;
  position: absolute;
  border-radius: 10px;
  max-width: calc(100% - 30px);
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  overflow-y: auto;
  max-height: calc(100vh / var(--vpUnitsFactor, 1) - 40px);
  min-height: 300px;
  min-width: 500px;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.labelText {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.inputFieldWrapper {
  width: 300px;
  margin-bottom: 2rem;
}

.inputFieldModifications :global(.InputField__label) {
  font-size: 14px;
  text-transform: uppercase;
}

.actionIcon {
  cursor: pointer;
}

:global(.Dropdown).teamRootFont {
  font-size: 12px;
  padding: 5px 0;
}

:global(.Dropdown__button).dropdownItem {
  height: 34px;
  width: 180px;
}

:global(.Dropdown__label).dropdownLabel {
  margin: 0 0 0 20px;
  text-align: left;
}

.featureDropdownLabel.featureDropdownLabel.featureDropdownLabel {
  margin: 0 0 0 20px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdownLabelWarning {
  color: var(--error);
}

:global(.Dropdown__icon).dropdownCaretIcon {
  margin: 0 10px 0 auto;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 0;
}

.gridWrapper {
  display: grid;
  /* stylelint-disable-next-line */
  grid-template-columns: 3fr 2fr 50px;
  row-gap: 10px;
}

.popoverToolsAppNameLabel {
  font-size: 12px;
  margin-left: 20px;
}

.popoverToolsLicensesLabel {
  font-size: 12px;
  text-transform: capitalize;
  margin-left: 26px;
}

.popoverTool {
  display: flex;
  font-size: 16px;
  align-items: center;
  margin: 7px 0;
  padding: 5px;
  user-select: none;
  background: var(--popoverToolsBackground);
}

.popoverToolText {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  background: var(--popoverToolsBackground);
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.popoverToolTextNotSelected {
  color: var(--popoverToolTextNotSelected);
}

.checkboxModifications.checkboxModifications.checkboxModifications {
  margin-left: 20px;
}

.roleWrapper {
  background: var(--popoverToolsBackground);
  display: flex;
  align-items: center;
}

.infoIconSize {
  width: 18px;
  height: 18px;
  color: var(--white);
  margin: 0 10px;
}

.licensesExceeded {
  color: var(--warning);
}

.emptyCellBackground {
  background: var(--popoverToolsBackground);
  height: 44px;
}

.toolSelectionIcon {
  width: 28px;
  height: 28px;
}

.toolIcon {
  width: 28px;
  height: 28px;
  background: grey;
  border: 0;
  border-top-left-radius: 5%;
  border-bottom-left-radius: 5%;
  background-size: cover;
  background-position: center bottom;
  transform: scale(x);
  background-repeat: no-repeat;
}

.togglerBackground {
  background: var(--popoverToolsBackground);
  padding: 8px 0;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.togglerAdjustment.togglerAdjustment.togglerAdjustment {
  margin-left: 0;
}

.error {
  color: var(--warning);
  margin-bottom: 10px;
}

.warning {
  color: var(--marigold);
  margin-bottom: 10px;
}

@media only screen and (max-width: 536px) {
  .editApplicationTeamRoot {
    width: calc(80vw / var(--vpUnitsFactor, 1));
  }

  .popoverToolsAppNameLabel {
    width: auto;
  }

  .popoverToolsLicensesLabel {
    margin-left: auto;
    margin-right: 28px;
  }
}

@supports not (zoom: 2) {
  @media only screen and (min-width: 2559px) and (min-height: 1199px) {
    .editApplicationTeamRoot {
      width: 900px;
    }

    .popoverToolsHeader {
      font-size: 1.5rem;
    }

    .popoverToolsAppNameLabel {
      margin-left: 80px;
      width: 550px;
    }

    .popoverTool {
      font-size: 1.5rem;
      margin: 15px 0;
      padding: 10px;
    }

    .popoverToolText {
      margin-left: 20px;
      width: 550px;
    }

    .toolSelectionIcon {
      width: 38px;
      height: 38px;
    }

    .toolIcon {
      width: 50px;
      height: 50px;
    }
  }
}
