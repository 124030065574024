.root {
  min-height: 100%;
  width: 100%;
  padding-bottom: 0;
  background: var(--blackBackground);
}

.rootError {
  background: var(--blackBackground);
  overflow: hidden;
}

.container {
  display: flex;
  flex-flow: row;
  min-height: calc(100vh / var(--vpUnitsFactor, 1) - 130px); /* minus footer & nav */
}

.content {
  width: 100%;
  padding: 0;
}

.contactUsBtn {
  width: 160px;
  height: 40px !important;
  position: fixed;
  bottom: 0;
  right: 200px;
  font-size: 14px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 20px;
  z-index: 1;
  color: white;
  text-align: center;
  background-color: var(--titleDarkBlueMagenta);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.overflowX {
  overflow-x: auto;
}

@media only screen and (max-width: 1024px) {
  .root {
    min-height: calc(100% - 36px); /* minus footer */
    padding-bottom: 0;
  }

  .container {
    min-height: calc(100vh / var(--vpUnitsFactor, 1) - 93px); /* minus footer & nav */
  }

  .content {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  .contactUsBtn {
    width: 70px;
    height: 35px;
    bottom: 0;
    left: calc(50% - 35px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .contactUsTitle {
    display: none;
  }

  .rootError .container {
    min-height: calc(100vh / var(--vpUnitsFactor, 1) - 36px);
  }
}
