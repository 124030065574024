.drawer {
  border-right: 1px solid var(--backgroundHeader);
  display: flex;
  padding: 10px 10px 10px 8px;
}

.menu {
  float: left;
  background-color: var(--backgroundGraySection);
  width: 300px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.navWrapper {
  margin: 40px 0 20px 0;
}

.navItemWrapper {
  display: flex;
  align-items: center;
  margin: 16px 33px 16px 0;
  padding: 10px 5px 10px 48px;
  cursor: pointer;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
}

.navItemActive > div {
  border-radius: 0 28px 28px 0;
  background-color: var(--activeMenuItemBackground);
  color: var(--textColor);
  font-weight: var(--fontWeightMedium);
}

.navItemText {
  font-size: 16px;
  color: inherit;
  font-weight: var(--fontWeightRegular);
  height: 30px;
  line-height: 35px;
  margin-left: 24px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.hr {
  border: 0.5px solid #939597;
  margin: 45px 20px 45px 0;
}

.tooltipText {
  display: block;
  max-width: 180px;
}

.navItemWrapper.disabled {
  opacity: 0.5;
  cursor: default;
}

@media only screen and (max-width: 1024px) {
  .drawer {
    padding: 0;
  }

  .menu {
    position: relative;
    width: 100%;
    padding-top: 0;
    height: 56px;
    box-shadow: none;
    min-height: 0;
  }

  .navWrapper {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
    padding: 0 0;
    border-top: 1px solid var(--borderColor);
  }

  .navItemWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 6px 0;
    cursor: pointer;
    color: var(--textColor);
  }

  .navItemText {
    margin-left: 0;
    margin-top: 8px;
    text-transform: uppercase;
    height: 10px;
    font-size: 8px;
    font-weight: var(--fontWeightRegular);
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: inherit;
  }

  .navItemActive > div {
    border-radius: 0;
    background-color: var(--activeMenuItemBackground);
    color: var(--textColor);
    font-weight: var(--fontWeightMedium);
    padding: 8px;
  }

  .hr {
    display: none;
  }

  .buttonWrapper {
    display: none;
  }
}

@media only screen and (max-width: 680px) {
  .menu,
  .navWrapper {
    height: 46px;
  }

  .navItemText {
    display: none;
  }
}
