.visibilityHidden.visibilityHidden.visibilityHidden {
  visibility: hidden;
}

.displayNone.displayNone.displayNone {
  display: none;
}

.margin10px.margin10px.margin10px {
  margin: 10px;
}

.cursorPointer.cursorPointer.cursorPointer {
  cursor: pointer;
}

.cursorDefault.cursorDefault.cursorDefault {
  cursor: default;
}

.marginLeft10px.marginLeft10px.marginLeft10px {
  margin-left: 10px;
}

.marginLeft20px.marginLeft20px.marginLeft20px {
  margin-left: 20px;
}
