.cloudStatus {
  display: flex;
  align-items: center;
  justify-content: right;
}

.center {
  justify-content: center;
}

.cloudStatusText {
  display: block;
  color: var(--labelColor);
  font-size: 12px;
  margin-right: 8px;
}

.cloudStatusIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tooltipText {
  display: block;
  max-width: 180px;
}

.spacing {
  width: 32px;
}
