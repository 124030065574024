.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  justify-content: center;
  background: #f2f3f5;
  text-align: center;
}

.header {
  display: flex;
  height: 94px;
  justify-content: center;
  background: url("~assets/svg/logo-black.svg") no-repeat center;
  background-size: 142px 39px;
}

.title {
  margin: 16px;
  font-size: 24px;
  color: #3c396f;
}
