.root {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  padding: 60px 60px 30px 60px;
}

.pageRoot {
  padding: 60px;
  min-height: 100%;
  width: 100%;
  overflow-x: auto;
}

.headerTextMargin {
  margin-right: 20px;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

.startNewTrial {
  color: var(--textColor);
  margin-bottom: 10px;
}

.tableWrapper {
  overflow-x: auto;
  scrollbar-width: thin;
}

.tableWrapper::-webkit-scrollbar {
  height: 7px;
}

.tableWrapper::-webkit-scrollbar-track {
  background: var(--backgroundGray);
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
}

.tableWrapper::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbarThumbHover);
}

.paginationCustomization {
  margin-left: auto;
  margin-top: 20px;
}

.override.tableRoot {
  line-height: 50px;
}

.override.tableMargin {
  margin-bottom: 10px;
}

.noProducts {
  color: var(--marigold);
  margin-bottom: 10px;
}

.override.override.rowHover:hover {
  background-color: var(--portalHoverBackground);
}

.override.extendTrialButtonCustomization {
  padding: 0;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.startTrialWrapper {
  display: flex;
  align-items: center;
}

.modifyPortalZIndex.modifyPortalZIndex :global(.PositionedOverlay) {
  z-index: 20000;
}

.override.startTrialRoot {
  margin-left: auto;
  margin-right: 16px;
}

.override.toggleContainer {
  margin: 10px 0 0 10px;
}

.override.override.toggleAdjustment input[type='checkbox']:checked + span::before {
  background-color: var(--primaryBlue);
}

.inputLabelWidth div:last-of-type {
  width: max-content;
}

.inputHourWrapper {
  display: flex;
}

.hoursLabel {
  margin-left: 10px;
  font-size: 20px;
  margin-top: 30px;
}

.btnLabel {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.serverError {
  margin-top: 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
}

.expiresInLabel {
  margin: 10px;
  color: var(--marigold);
  font-weight: var(--fontWeightNormal);
}

.iconSize.iconSize {
  width: 24px;
  height: 24px;
}

.limitTextWidth {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modalBottom.modalBottom {
  padding-bottom: 24px;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .btnLabel {
    padding: 10px;
    font-weight: var(--fontWeightNormal);
    color: var(--labelColor);
  }

  .subTitle {
    margin: 12px 50px 30px 10px;
  }
}
