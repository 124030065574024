/* stylelint-disable */

:global(.plume-tooltip.plume-tooltip-zoom-enter),
:global(.plume-tooltip.plume-tooltip-zoom-leave) {
  display: block;
}

:global(.plume-tooltip-zoom-enter),
:global(.plume-tooltip-zoom-appear) {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

:global(.plume-tooltip-zoom-leave) {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}

:global(.plume-tooltip-zoom-enter.plume-tooltip-zoom-enter-active),
:global(.plume-tooltip-zoom-appear.plume-tooltip-zoom-appear-active) {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

:global(.plume-tooltip-zoom-leave.plume-tooltip-zoom-leave-active) {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

:global(.plume-tooltip) {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}

:global(.plume-tooltip-hidden) {
  display: none;
}

:global(.plume-tooltip-placement-top),
:global(.plume-tooltip-placement-topLeft),
:global(.plume-tooltip-placement-topRight) {
  padding: 5px 0 9px 0;
}

:global(.plume-tooltip-placement-right),
:global(.plume-tooltip-placement-rightTop,) :global(.plume-tooltip-placement-rightBottom) {
  padding: 0 5px 0 9px;
}

:global(.plume-tooltip-placement-bottom),
:global(.plume-tooltip-placement-bottomLeft),
:global(.plume-tooltip-placement-bottomRight) {
  padding: 9px 0 5px 0;
}

:global(.plume-tooltip-placement-left),
:global(.plume-tooltip-placement-leftTop),
:global(.plume-tooltip-placement-leftBottom) {
  padding: 0 9px 0 5px;
}

:global(.plume-tooltip-inner) {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: var(--tooltipBackground);
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}


:global(.plume-tooltip-arrow) {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

:global(.plume-tooltip-placement-top .plume-tooltip-arrow),
:global(.plume-tooltip-placement-topLeft .plume-tooltip-arrow),
:global(.plume-tooltip-placement-topRight .plume-tooltip-arrow) {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--tooltipBackground);
}

:global(.plume-tooltip-placement-top .plume-tooltip-arrow) {
  left: 50%;
}

:global(.plume-tooltip-placement-topLeft .plume-tooltip-arrow) {
  left: 15%;
}

:global(.plume-tooltip-placement-topRight .plume-tooltip-arrow) {
  right: 15%;
}

:global(.plume-tooltip-placement-right .plume-tooltip-arrow),
:global(.plume-tooltip-placement-rightTop .plume-tooltip-arrow),
:global(.plume-tooltip-placement-rightBottom .plume-tooltip-arrow) {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: var(--tooltipBackground);
}

:global(.plume-tooltip-placement-right .plume-tooltip-arrow) {
  top: 50%;
}

:global(.plume-tooltip-placement-rightTop .plume-tooltip-arrow) {
  top: 15%;
  margin-top: 0;
}

:global(.plume-tooltip-placement-rightBottom .plume-tooltip-arrow) {
  bottom: 15%;
}

:global(.plume-tooltip-placement-left .plume-tooltip-arrow),
:global(.plume-tooltip-placement-leftTop .plume-tooltip-arrow),
:global(.plume-tooltip-placement-leftBottom .plume-tooltip-arrow) {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: var(--tooltipBackground);
}

:global(.plume-tooltip-placement-left .plume-tooltip-arrow) {
  top: 50%;
}

:global(.plume-tooltip-placement-leftTop .plume-tooltip-arrow) {
  top: 15%;
  margin-top: 0;
}

:global(.plume-tooltip-placement-leftBottom .plume-tooltip-arrow) {
  bottom: 15%;
}

:global(.plume-tooltip-placement-bottom .plume-tooltip-arrow),
:global(.plume-tooltip-placement-bottomLeft .plume-tooltip-arrow),
:global(.plume-tooltip-placement-bottomRight .plume-tooltip-arrow) {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--tooltipBackground);
}

:global(.plume-tooltip-placement-bottom .plume-tooltip-arrow) {
  left: 50%;
}

:global(.plume-tooltip-placement-bottomLeft .plume-tooltip-arrow) {
  left: 15%;
}

:global(.plume-tooltip-placement-bottomRight .plume-tooltip-arrow) {
  right: 15%;
}

:global(.plume-tooltip.hide-arrow .plume-tooltip-arrow) {
  display: none;
}
