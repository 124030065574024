.root {
  position: relative;
  display: inline-block;
  height: 28px;
  width: 28px;
  margin-right: 30px;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
}

.root input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 28px;
  width: 28px;
  background-color: var(--white);
  border: 1px solid #6269ff;
  border-radius: 2px;
}

.root input:checked ~ .checkmark {
  background-color: #6269ff;
}

.checkmark::after {
  content: "";
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 8px;
  display: none;
  width: 9px;
  height: 18px;
}

.root input:checked ~ .checkmark::after {
  display: block;
}

.error {
  position: absolute;
  margin-top: 16px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
}
