.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  position: absolute;
  background: #000;
}

.header {
  display: flex;
  height: 94px;
  justify-content: center;
  background: url('~assets/svg/inline/logo-header.svg') no-repeat 48px;
  background-size: 184px 50px;
  background-color: var(--loginHeaderBackground);
}

.content {
  height: calc(100vh / var(--vpUnitsFactor, 1) - 94px);
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  flex-grow: 1;
  background: url('~assets/svg/inline/login-bg.svg') no-repeat center 50%;
  background-size: 100%;
}

.idpModalContent {
  display: flex;
  justify-content: center;
}

.contentWrapper {
  margin: 0 auto;
  max-width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
}

.login {
  width: 400px;
  height: 312px;
  background: var(--loginFormBackground);
  backdrop-filter: blur(60px);
  border-radius: 8px;
  padding: 0 40px;
}

@-moz-document url-prefix() {
  .login {
    background: rgb(0, 0, 0, 0.4);
  }
}

.loginTitle {
  color: var(--secondaryPlumeUi);
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  margin: 0;
  margin-top: 50px;
  text-align: center;
  font-family: wigrumweb, sans-serif;
}

.loginSubtitle {
  font-family: '-OC Rey', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 130%;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  color: var(--textColor);
}

.loginButton {
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
}
