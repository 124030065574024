.select {
  background-color: var(--white);
  border-radius: 0;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  padding-left: 2px;
}

.select select {
  background-color: var(--white);
}

.select::after {
  background: var(--white);
  pointer-events: none;
  line-height: 3.8;
}

.arrow {
  background: var(--white);
}

.arrowDown {
  top: 28px;
}
