.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  position: absolute;
}

.header {
  display: flex;
  height: 94px;

  /* margin-left: 60px; */
  justify-content: center;
  background: url("~assets/svg/logo-white.svg") no-repeat center;
  background-color: var(--backgroundHeader);
  background-size: 142px 39px;
}

.content {
  flex-grow: 1;
  background: var(--backgroundGray);
  padding: 66px 15px 141px;
}

.contentWrapper {
  margin: 0 auto;
  max-width: 360px;
}

.subTitle {
  font-size: 13px;
  line-height: 1.3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--textColor);
}

.title {
  font-size: 54px;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 36px;
  text-align: center;
  color: var(--textColor);
}

.titleWithDescription {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.38;
  color: var(--labelColor);
}

.stepsHeader {
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
  background-color: #3c396f;
  color: var(--white);
}

.stepsItem {
  width: 200px;
  line-height: 45px;
  font-size: 13px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.number {
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px 9px 4px 9px;
  margin: 0 10px;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
}

.active {
  background-color: white;
  color: #3c396f;
}

@media only screen and (max-width: 1024px) {
  .header {
    height: 56px;
    background-size: 127px 34px;
    margin-left: 10px;
  }

  .content {
    padding: 34px 15px 34px;
  }

  .title {
    font-size: 44px;
    margin-bottom: 44px;
    color: var(--textColor);
  }

  .titleWithDescription {
    margin-bottom: 30px;
  }

  .stepsHeader {
    display: flex;
    justify-content: space-evenly;
  }

  .stepsItem {
    width: auto;
  }
}

.tc {
  margin-bottom: 32px;
  display: flex;
}

.tcText {
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 22px;
  color: var(--labelColor);
}
