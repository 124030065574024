.tooltipInfoText {
  white-space: pre-line;
  width: var(--infoWindowSize, 280px);
  max-height: var(--infoWindowSize, 280px);
  font-size: var(--infoWindowFontSize, inherit);
  padding: 5%;
  overflow-y: auto;
  scrollbar-width: thin;
}

.tooltipInfoText::-webkit-scrollbar {
  width: 7px;
}

.tooltipInfoText::-webkit-scrollbar-track {
  background: var(--backgroundGray);
}

.tooltipInfoText::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
}

.tooltipInfoText::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbarThumbHover);
}

.infoTooltipContainter {
  cursor: pointer;
}

.infoButton {
  width: var(--infoButtonSize, 40px);
  height: var(--infoButtonSize, 40px);
  padding: calc(var(--infoButtonSize, 40px) * 0.125);
  display: inline-block;
  fill: var(--textColor);
  background: var(--selectBackground);
  border-radius: calc(var(--infoButtonSize, 40px) * 0.15);
  transition: 0.3s;
}

.infoButton:hover {
  opacity: 0.7;
}
