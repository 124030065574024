.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh / var(--vpUnitsFactor, 1));
  width: 100%;
  justify-content: space-between;
  background: var(--backgroundGray);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh / var(--vpUnitsFactor, 1) - 130px);
}

.tcWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundGraySection);
  max-width: 910px;
  border-radius: 20px;
  padding: 20px 80px 30px;
}

.rawTcWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundGraySection);
  width: 95%;
  height: 90%;
  border-radius: 20px;
  padding: 20px 80px 30px;
}

.tcTitle {
  font-size: 24px;
  color: var(--textColor);
  font-weight: var(--fontWeightMedium);
  margin: 20px 20px 30px;
  text-align: center;
}

.tcContent {
  height: calc(100vh / var(--vpUnitsFactor, 1) - 130px - 94px - 80px);
  width: 782px;
  overflow-y: scroll;
  padding: 20px;
  font-size: 12px;
  line-height: 13px;
  color: var(--textColor);
  background: var(--backgroundGray);
}

.header {
  display: flex;
  height: 94px;
  justify-content: center;
  background: url('~assets/svg/logo-white.svg') no-repeat center;
  background-size: 142px 39px;
}

/* stylelint-disable */
p.p1 {
  margin: 0px 0px 5px 0px;
  text-align: justify;
  font: 12px Arial;
}
p.p2 {
  margin: 0px 0px 5px 0px;
  text-align: justify;
  font: 12px Arial;
}
p.p3 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;
}
p.p4 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;

  min-height: 11px;
}
p.p5 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;
}
p.p6 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  font: 12px Arial;

  min-height: 11px;
}
p.p7 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;

  min-height: 11px;
}
p.p8 {
  margin: 0px 0px 0px 45px;
  text-align: justify;
  text-indent: -18px;
  font: 12px Arial;
}
p.p9 {
  margin: 0px 0px 5px 36px;
  text-align: justify;
  text-indent: -18px;
  font: 12px Arial;
}
p.p10 {
  margin: 0px 0px 0px 72px;
  text-align: justify;
  font: 12px Arial;

  min-height: 11px;
}
p.p11 {
  margin: 0px 0px 5px 36px;
  text-align: justify;
  font: 12px Arial;
}
p.p12 {
  margin: 0px 0px 5px 36px;
  text-align: justify;
  text-indent: -18px;
  font: 12px Arial;

  min-height: 11px;
}
p.p13 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  font: 12px Arial;

  min-height: 11px;
}
p.p15 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  font: 12px Arial;
}
p.p16 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  font: 12px Arial;
}
p.p17 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  font: 12px Arial;
  min-height: 11px;
}
p.p18 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;
  min-height: 11px;
}
p.p19 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  text-indent: -18px;
  font: 12px Arial;
}
p.p20 {
  margin: 0px 0px 0px 36px;
  text-align: justify;
  text-indent: -18px;
  font: 13px Arial;

  min-height: 12px;
}
p.p21 {
  margin: 0px 0px 12px 0px;
  text-align: justify;
  font: 12px Arial;

  min-height: 11px;
}
li.li1 {
  margin: 0px 0px 5px 0px;
  text-align: justify;
  font: 12px Arial;
}
li.li3 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;
}
li.li14 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 12px Arial;
}
span.s1 {
  text-decoration: underline;
}
span.s2 {
  font: 13px Arial;
}
span.s4 {
  font: 12px Symbol;
}
span.s5 {
  letter-spacing: 0.9px;
}
span.s6 {
  letter-spacing: 0.9px;
}
span.s7 {
  font: 12px 'Courier New';
}
span.s8 {
  text-decoration: underline;
}
span.s9 {
  font: 12px 'Courier New';
}
span.appleTableSpan {
  white-space: pre;
}
ol.ol1 {
  list-style-type: lower-alpha;
}
ul.ul1 {
  list-style-type: disc;
}
ul.ul2 {
  list-style-type: square;
}

/* stylelint-enable */

@media only screen and (max-width: 1024px) {
  .root {
    display: flex;
    justify-content: normal;
  }

  .header {
    height: 56px;
    background-size: 127px 34px;
    margin-left: 10px;
  }

  .tcWrapper {
    margin: 30px 20px 20px 30px;
    max-width: 100%;
    padding: 20px 10px;
  }

  .tcTitle {
    font-size: 16px;
    color: var(--textColor);
    font-weight: var(--fontWeightMedium);
    margin: 0 10px 10px;
    text-align: center;
  }

  .tcContent {
    max-height: calc(100vh / var(--vpUnitsFactor, 1) - 210px);
    height: auto;
    width: 100%;
    overflow-y: scroll;
    padding: 20px;
    font-size: 12px;
    line-height: 13px;
  }
}
