.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  pointer-events: all;
  padding-top: 60px;
  max-height: calc(65vh / var(--vpUnitsFactor, 1));
  min-height: calc(40vh / var(--vpUnitsFactor, 1));
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
  min-width: 584px;
}

.companyWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 20px 40px 20px 40px;
}

.companyWrapperEven {
  background-color: var(--selectBackground);
}

.companyWrapperOdd {
  background-color: var(--backgroundGraySection);
}

.companyInfoWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.companyInfoColumn {
  flex: 1;
}

.labelText {
  margin-bottom: 10px;
  padding: 5px 10px;
  width: 100%;
  color: var(--labelColor);
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  text-align: center;
  white-space: pre-line;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: calc(95vh / var(--vpUnitsFactor, 1));
  }

  .companyWrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subTitle {
    min-width: calc(80vw / var(--vpUnitsFactor, 1));
    margin: 12px 10px 30px 10px;
    padding: 0 25px;
  }
}
