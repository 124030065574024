.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: auto;
  pointer-events: all;
  padding: 60px 40px 30px 40px;
}

.selectInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.emailContactInfoPopover {
  --infoButtonSize: 20px;
}

.infoButton {
  width: 40px;
  height: 40px;
  padding: 5px;
  display: inline-block;
  fill: var(--textColor);
  background: var(--selectBackground);
  border-radius: 15%;
  transition: 0.3s;
}

.infoButton:hover {
  opacity: 0.7;
}

.separator {
  height: 2px;
  background: var(--labelColor);
  width: 100%;
  margin: 20px 0;
}

.panoramaUsersOverLimit {
  color: var(--error);
  margin-left: 10px;
}

.panoramaUsersCountOk {
  margin-left: 10px;
  color: var(--marigold);
}

.panoramaLicensesCountOk {
  color: var(--marigold);
}

.dropdownClearSelection {
  display: flex;
}

.redText {
  color: var(--error);
  margin-right: 10px;
}

.infoTooltipContainter {
  display: inline-block;
  cursor: pointer;
}

.rolesInfoPopover {
  --infoButtonSize: 40px;

  margin-right: 10px;
}

.override.togglerBackground {
  background: rgba(255, 255, 255, 0.1);
  height: 40px;
  border-radius: 20px;
}

.override.togglerToggle {
  border-radius: 20px;
}

.override.togglerLabel {
  padding: 16px;
}

.selectAppsArrow {
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
}

.selectAppsArrow:hover {
  opacity: 0.7;
}

.selectAppsArrow svg {
  padding: 10px;
  height: 40px;
  width: 40px;
  background: var(--selectBackground);
  border-radius: 15%;
}

.appsSubtitle {
  font-size: 18px;
  margin: 0 10px 10px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.blur {
  filter: blur(6px);
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv,
.btnLabelTools {
  text-align: center;
}

.addTools {
  text-align: center;
}

.sendInviteBtn {
  margin-top: 20px;
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.userToolsRoles {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 0 10px;
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  background-color: var(--selectBackground);
  text-align: center;
}

.userToolsRolesDisabled {
  padding: 0 0 0 20px;

  --selectBackground: transparent;
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  height: 82px;
}

.input input {
  padding: 24px 0 0;
}

.inputDisabled input {
  color: var(--labelColor);
}

.input:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.marginLeft {
  margin-left: 260px;
}

.deleteBtn {
  text-align: center;
}

.deleteBtn button {
  margin-bottom: 0;
  background: none;
  color: var(--primaryBlue);
  font-size: 16px;
  padding: 12px;
  font-weight: var(--fontWeightRegular);
}

.modalHidden {
  visibility: hidden;
}

.btnLabel {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
  text-align: center;
}

.toolsWrapper {
  margin: 0 10px;
}

.toolText {
  margin-top: -10px;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--fontWeightMedium);
}

.toolsCb {
  margin-bottom: 10px;
  display: inline-flex;
  width: 30%;
}

.toolsCbDisabled span {
  border: 1px solid var(--labelColor);
}

.toolsText {
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 22px;
  color: var(--labelColor);
}

.roleSelect {
  display: inline-block;
  width: calc(50% - 5px);
}

.formInputs {
  display: flex;
  flex-wrap: wrap;
}

.formInput {
  flex: 0 calc(50% - 5px);
}

.formInput.checkboxInput {
  display: flex;
  align-items: center;
}

.emailContactLabel {
  color: var(--textColor);
  font-size: 12px;
  margin: 0 10px 0 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 2.17;
  font-weight: var(--fontWeightNormal);
}

.rowSeparator {
  height: 10px;
  width: 100%;
}

.uiInfoIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.override.toggleRoot {
  margin-left: auto;
  margin-right: 10px;
}

.override.override.toggleAdjustment input[type='checkbox']:checked + span::before {
  background-color: var(--primaryBlue);
}

.checkBox {
  margin-left: auto;
  margin-right: 10px;
}

.checkBox label {
  margin-right: 0;
}

.checkBox,
.checkBox label {
  width: 18px;
  height: 18px;
}

.portalAdminCheckBoxInput {
  width: 50%;
  justify-content: center;
  margin: 10px auto 0 auto;
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.toolsWarning {
  margin: 10px 10px 0 10px;
  font-size: 14px;
  color: var(--warning);
}

.addToolsBtn {
  height: 50px;
  margin-bottom: 20px;
}

.addToolsBtn button {
  font-size: 18px;
  height: 50px;
  color: var(--textColor);
  background: var(--secondaryButtonBackground);
}

.totalPanoramaLicenses {
  position: fixed;
  transform: translateY(-30px);
  user-select: none;
  text-wrap: nowrap;
}

.teamsDropdownList.teamsDropdownList.teamsDropdownList {
  top: 70px;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    height: calc(90vh / var(--vpUnitsFactor, 1));
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .btnLabel {
    padding: 10px;
    font-weight: var(--fontWeightNormal);
    color: var(--labelColor);
  }

  .subTitle {
    margin: 12px 10px 30px 10px;
  }

  .input {
    height: 60px;
  }

  .roleSelect {
    display: inline-block;
    width: 100%;
  }

  .formInputs {
    margin-bottom: 30px;
  }

  .formInput {
    flex: 0 100%;
    margin-bottom: 10px;
  }

  .input > div {
    padding-top: 1px;
  }

  .toolsCb {
    display: inline-flex;
    width: 50%;
  }
}

@supports not (zoom: 2) {
  @media only screen and (min-width: 2559px) and (min-height: 1199px) {
    .root {
      width: 1500px;
      width: clamp(1205px, calc(50vw / var(--vpUnitsFactor, 1)), 1500px);
    }

    .subTitle {
      font-size: 2.5rem;
    }

    .appsSubtitle {
      font-size: 2.5rem;
      margin: 10px 10px 20px 10px;
    }

    .selectInfoContainer {
      margin: 10px 0;
    }

    .input {
      height: 10rem;
    }

    .input label,
    .btnLabel,
    .serverError,
    .toolsWarning,
    .emailContactLabel,
    .input > div {
      font-size: 1.5rem;
    }

    .input input {
      font-size: 2.5rem;
      height: 8rem;
    }

    .userToolsRoles {
      height: 4rem;
    }

    .userToolsRoles select {
      font-size: 2rem;
    }

    .userToolsRoles > div {
      height: 100%;
    }

    .userToolsRoles > div > div {
      top: 10px;
    }

    .btnDiv button {
      height: 4rem;
      font-size: 2rem;
      width: 400px;
    }

    .deleteBtn button {
      font-size: 2rem;
      height: 4rem;
    }

    .closeButtonScaling {
      width: 40px;
      height: 40px;
    }

    .infoButton {
      width: 64px;
      height: 64px;
      padding: 8px;
    }

    .rolesInfoPopover {
      --infoWindowSize: 500px;
      --infoWindowFontSize: 1.5rem;
    }

    .emailContactInfoPopover {
      --infoButtonSize: 36px;
      --infoWindowSize: 500px;
      --infoWindowFontSize: 1.5rem;
    }

    .checkBox,
    .checkBox label,
    .checkBox label span {
      width: 30px;
      height: 30px;
    }

    .checkBox span::after {
      top: 4px;
      left: 10px;
      width: 8px;
      height: 16px;
    }

    .selectAppsArrow svg {
      padding: 16px;
      height: 62px;
      width: 62px;
    }
  }
}
