.root {
  position: absolute;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  padding: 60px 60px 30px 60px;
  overflow-y: auto;
  width: 768px;
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.centerTabs :global(.FlatTabs) {
  justify-content: center;
}

.btnDiv {
  position: relative;
  display: flex;
  margin: 0 auto;
  padding-top: 15px;
  width: 230px;
  height: 60px;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.error {
  margin: 0;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.fileInput,
.hidden {
  visibility: hidden;
  cursor: auto !important;
}

.fileSelection {
  margin: 0;
  width: calc(100% - 20px);
  width: 100%;
  height: 140.5px;
  border: 1px dashed var(--borderColor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.labelLogoTitle {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 24px;
  color: var(--textColor);
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding-bottom: 20px;
}

.labelLogoText {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 16px;
  color: var(--primaryBlue);
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.labelInfoText {
  margin-top: 10px;
  color: var(--labelColor);
  font-size: 12px;
}

.labelPreviewTitle {
  margin: 10px 0;
  color: var(--titleDarkBlueMagenta);
  font-size: 12px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
}

.labelRemoveFile {
  margin-top: 10px;
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
}

.previewLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewLogoOriginalBackground {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 100px;
  background: var(--labelColor);
}

.previewLogoOriginalImage {
  max-width: 100%;
  max-height: 100%;
  padding: 5px;
}

.previewLogoOriginalPdf {
  max-width: 100%;
  max-height: 100%;
  display: block;
  overflow: hidden;
}

.logoPdfPageWidth > div {
  background-color: transparent !important;
  max-width: 100%;
  height: auto !important;
  width: auto !important;
}

.logoPdfPageWidth > div > svg {
  padding: 2px;
  max-width: 100%;
  height: auto;
}

.logoPdfPageWidth > div > svg > g > g:first-of-type > g > path {
  opacity: 0;
}

.logoPdfPageHeight > div {
  background-color: transparent !important;
  max-width: 100%;
  height: auto !important;
  width: auto !important;
}

.logoPdfPageHeight > div > svg {
  padding: 2px;
  width: auto;
  height: 100px;
}

.logoPdfPageHeight > div > svg > g > g:first-of-type > g > path {
  opacity: 0;
}

@media only screen and (max-width: 1024px) {
  .root {
    padding: 10px;
    width: calc(100% - 10px);
    height: calc(100vh / var(--vpUnitsFactor, 1) - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .previewLogoOriginalBackground {
    width: 100%;
  }

  .subTitle {
    margin: 15px 40px;
  }

  .labelInfoText {
    margin: 5px 10px;
  }

  .fileSelection {
    width: auto;
  }
}
