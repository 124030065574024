.root {
  text-align: center;
  height: 100%;
  width: 100%;
  padding-bottom: 0;
  background: var(--backgroundGray);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ndaBoxStyle {
  margin-top: 30px;
}

.sectionContent {
  flex-grow: 1;
}

.ndaContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ndaContent {
  width: 600px;
  background-color: var(--backgroundGraySection);
  border-radius: 20px;
  text-align: center;
  margin: 40px;
  padding: 40px;
}

.ndaContentTitle {
  font-size: 20px;
  color: var(--textColor);
  margin-bottom: 10px;
}

.ndaContentIcon {
  margin-bottom: 10px;
}

.content {
  margin: 40px 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ndaSignBtn button {
  width: 280px;
  height: 48px;
  margin: 22px;
}

.transferBtn button {
  width: 280px;
  height: 48px;
  margin: 22px;
  border: 2px solid var(--primaryBlue);
  background: none;
  color: var(--primaryBlue);
}

.maxWidthHeight {
  width: 100%;
  height: calc(100vh / var(--vpUnitsFactor, 1));
  border: none;
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.navItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 33px 16px 0;
  padding: 10px 5px 10px 48px;
  cursor: pointer;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
}

.navItemText {
  font-size: 16px;
  color: inherit;
  font-weight: var(--fontWeightRegular);
  height: 30px;
  line-height: 35px;
  margin-left: 24px;
}

/** Card */

.card {
  margin: 80px 0;
}

.cardTitle {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
}

.cardDesc {
  line-height: 1.5;
  margin-bottom: 16px;
  color: var(--textColor);
}

.cardCtaBtn {
  height: 44px;
  padding: 0 20px;
  font-weight: var(--fontWeightMedium);
}

@media only screen and (max-width: 1024px) {
  .card {
    margin: 40px 0;
  }

  .ndaContentWrapper {
    margin: 40px 20px;
  }

  .ndaContent {
    width: 100%;
    border-radius: 20px;
    margin: 0;
    padding: 20px;
  }

  .content {
    margin: 20px 0;
  }

  .ndaSignBtn button {
    max-width: 240px;
    height: 48px;
    margin: 22px;
  }

  .navItemWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 6px 0;
    cursor: pointer;
    color: var(--textColor);
  }

  .navItemText {
    margin-left: 0;
    margin-top: 8px;
    text-transform: uppercase;
    height: 10px;
    font-size: 8px;
    font-weight: var(--fontWeightRegular);
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: inherit;
  }

  .signOutButton {
    display: none;
  }
}
