.root {
  position: absolute;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  pointer-events: all;
  padding: 60px 60px 30px 60px;
  overflow-y: scroll;
  max-height: calc(100vh / var(--vpUnitsFactor, 1) - 40px);
  scrollbar-width: none;
}

.root::-webkit-scrollbar {
  display: none;
}

.noScrollbar {
  overflow: hidden;
}

.showLineBreaks {
  white-space: pre-line;
}

.darkenBackground {
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  position: absolute;
  background-color: var(--darkenBackground);
}

.tabsContainer {
  height: 30px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.tab {
  border: solid 2px var(--labelColor);
  border-radius: 10px;
  padding: 0 5px;
  height: 28px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--labelColor);
}

.tabSelected {
  color: var(--backgroundGray);
  background-color: var(--textColor);
}

.bothProducts {
  width: 200px;
}

.copyIcon {
  width: 24px;
  height: 24px;
  color: var(--textColor);
  margin-right: 5px;
}

.copyContainer {
  display: flex;
  justify-content: center;
}

.copyButton {
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.copyLogoButton {
  margin: 10px 0;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.backButton {
  position: absolute;
  left: 30px;
  top: 26px;
  cursor: pointer;
}

.backButton svg path {
  stroke: var(--textColor);
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.formInputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.formInputFull {
  flex: 0 calc(100% - 5px);
  margin-bottom: 10px;
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
}

.input input {
  padding: 24px 0 0;
}

.input:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.btnDiv {
  position: relative;
  display: flex;
  margin: 0 auto;
  margin-top: 15px;
  width: 280px;
}

.btnDiv button {
  margin-bottom: 0;
}

.sendInviteBtn {
  width: 280px;
  border-radius: 25px;
  display: inline-block;
}

.error {
  margin: 0;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.infoIcon {
  margin-left: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.dropdownPriorityItem {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: grid;
  /* stylelint-disable-next-line */
  grid-template-columns: 25px auto 34px;
  font-family: inherit;
  font-size: inherit;
  height: 40px;
  padding: 0 16px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  user-select: none;
}

.dropdownPriorityItem:hover {
  background-color: hsla(0, 0%, 100%, 0.13);
  border-radius: 4px;
}

.dropdownPriorityTick {
  height: 14px;
  margin-left: 20px;
  visibility: hidden;
}

.dropdownPriorityItemSelected .dropdownPriorityTick {
  stroke: #c6c6c6;
  visibility: visible;
}

.dropdownPriorityItem.dropdownPriorityItemSelected:hover .dropdownPriorityTick {
  stroke: #17e3ae;
  visibility: visible;
}

.dropdownPriorityArrow.dropdownPriorityArrow {
  padding: 0;
}

.dropdownLabelWarning {
  color: var(--error);
}

.filePreview {
  width: 284px;
  height: 40px;
}

.filePreview svg,
.filePreview canvas {
  max-width: 284px;
  max-height: 40px;
}

.reactPdfPage > div {
  background-color: transparent !important;
}

.fileInput,
.hidden {
  visibility: hidden;
  cursor: auto !important;
}

.fileSelection {
  margin: 0;
  width: calc(100% - 20px);
  width: 100%;
  height: 90px;
  border: 1px dashed var(--borderColor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.labelLogoTitle {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 24px;
  color: var(--titleDarkBlueMagenta);
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding-bottom: 20px;
}

.labelLogoText {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 16px;
  color: var(--primaryBlue);
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.filesHeader {
  display: flex;
  justify-content: space-between;
}

.labelText {
  margin-top: 10px;
  padding: 5px 10px;
  width: 100%;
  background-color: var(--primaryBlue);
  color: var(--white);
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  text-align: center;
  letter-spacing: 0.5px;
}

.labelInfoText {
  margin-top: 10px;
  color: var(--labelColor);
  font-size: 12px;
}

.labelLink {
  visibility: hidden;
  color: var(--primaryBlue);
  font-size: 12px;
  cursor: pointer;
}

.labelPreviewTitle {
  margin: 10px 0;
  color: var(--titleDarkBlueMagenta);
  font-size: 12px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
}

.labelRemoveFile {
  margin-top: 10px;
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
}

.setupCompletedWrapper {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: var(--labelColor);
}

.setupCompletedWrapper div {
  margin: 5px 0;
}

.setupNotification {
  display: inline-block;
  margin-right: 10px;
  padding: 1px 0 0 1px;
  width: 18px;
  height: 18px;
  color: white;
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  background: #fa1478;
  border: 1px solid #fa1478;
  border-radius: 50%;
}

.previewHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.previewItem {
  display: inline-block;
  margin: 0 10px;
  padding-bottom: 5px;
  font-size: 14px;
  color: var(--textColor);
  cursor: pointer;
}

.previewItemActive {
  color: var(--textColor);
  border-bottom: 1px solid var(--primaryBlue);
}

.homeScreenshotsContainer {
  display: flex;
}

.imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 375px;
  margin: 0 5px;
}

.imagePreview > img {
  width: 375px;
}

.imagePreviewIosHome {
  margin-top: -10px;
}

.previewContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.arrowLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding-right: 20px;
  cursor: pointer;
}

.arrowRight {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding-left: 20px;
  cursor: pointer;
}

.previewLogoWrapper {
  display: flex;
  align-items: flex-end;
}

.previewLogoBg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;
  background: var(--labelColor);
}

.previewLogoBg div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewLogoLink {
  color: var(--labelColor);
  font-size: 14px;
  line-height: 8px;
  margin-left: 5px;
  cursor: pointer;
}

/* Logo preview */

.androidHsHomePassV2 {
  --logoTop: 23px;
  --logoLeft: 13px;
  --logoHeight: 20px;
}

.androidHsScrolledHomePassV2 {
  --logoTop: 17px;
  --logoLeft: 13px;
  --logoHeight: 16px;
}

.androidPreviewHomePass {
  --logoTop: 35px;
  --logoLeft: 32px;
  --logoHeight: 20px;
}

.androidPreviewWorkPass {
  --logoTop: 25px;
  --logoLeft: 12px;
  --logoHeight: 20px;
}

.iosHsHomePassV2 {
  --logoTop: 48px;
  --logoLeft: 26px;
  --logoHeight: 20px;
}

.iosHsScrolledHomePassV2 {
  --logoTop: 46px;
  --logoLeft: 26px;
  --logoHeight: 16px;
}

.iosPreviewHomePass {
  --logoPdfDeepDivDisplay: block;
  --logoTop: 70px;
  --logoLeft: auto;
  --logoHeight: 20px;
}

.iosPreviewWorkPass {
  --logoTop: 49px;
  --logoLeft: 25px;
  --logoHeight: 20px;
}

.ioslogo {
  position: absolute;
  top: var(--logoTop);
  left: var(--logoLeft);
  text-align: center;
}

.ioslogo > div > div > div {
  display: var(--logoPdfDeepDivDisplay, flex) !important;
}

.fileLogoPreview svg,
.fileLogoPreview canvas {
  width: auto;
  max-height: var(--logoHeight);
}

.fileLogoPreview svg path {
  fill: var(--avatarBackground);
}

.androidLogo,
.androidLogo svg {
  position: absolute;
  top: var(--logoTop);
  left: var(--logoLeft);
  width: auto;
  max-height: var(--logoHeight);
}

.androidLogo svg path {
  fill: var(--avatarBackground);
}

/* no-descending-specificity stylelint */
.reactPdfPage > div > svg > g > g:first-of-type > g > path {
  opacity: 0;
}

/* Preview Common */

.previewOverlay {
  position: absolute;
  width: 375px;
  background: var(--titleDarkBlueMagenta);
  opacity: 0.2;
}

.previewFieldEnabled {
  position: absolute;
  width: var(--screenshotWidth);
  height: var(--itemHeight);
  background: white;
  opacity: 0;
}

.previewFieldDisabled {
  position: absolute;
  width: var(--screenshotWidth);
  height: var(--itemHeight);
  background: var(--blackColor);
  opacity: 0.8;
  color: white;
  text-align: center;
  line-height: var(--itemHeight);
}

/* Pods Preview */

.podsWorkPass {
  --screenshotWidth: 375px;
  --itemHeight: 56px;
  --topOverlayHeight: 542px;
  --borderRadius: 0;
  --buyPodBottomGap: 214px;
}

.iosPodsHomePass {
  --screenshotWidth: 375px;
  --itemHeight: 64px;
  --topOverlayHeight: 575px;
  --borderRadius: 20px;
  --addPodBottomGap: 172px;
  --buyPodBottomGap: 60px;
}

.androidPodsHomePass {
  --screenshotWidth: 375px;
  --itemHeight: 58px;
  --topOverlayHeight: 626px;
  --borderRadius: 0;
  --addPodBottomGap: 130px;
  --buyPodBottomGap: 64px;
}

.podsHomePassV2 {
  --screenshotWidth: 375px;
  --itemHeight: 56px;
  --topOverlayHeight: 658px;
  --borderRadius: 0;
  --addPodBottomGap: 98px;
  --buyPodBottomGap: 42px;
}

.podsOverlayTop {
  top: 0;
  height: var(--topOverlayHeight);
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.addPod {
  bottom: var(--addPodBottomGap);
}

.buyPodUrlEnabled {
  bottom: var(--buyPodBottomGap);
}

.buyPodUrlDisabled {
  bottom: var(--buyPodBottomGap);
}

.podsOverlayBottom {
  bottom: 0;
  height: var(--buyPodBottomGap);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

/* Support Preview */

.iosSupportHomePass {
  --screenshotWidth: 375px;
  --itemHeight: 64px;
  --topGap: 144px;
  --contactUsGap: 208px;
  --callUsGap: 272px;
  --callUsWidth: 230px;
  --supportMiddle1Gap: 334px;
  --supportMiddle1Height: 194px;
  --termsGap: 528px;
  --supportMiddle2Gap: 592px;
  --privacyGap: 656px;
  --bottomGap: 92px;
  --borderRadius: 20px;
}

.androidSupportHomePass {
  --screenshotWidth: 375px;
  --itemHeight: 56px;
  --topGap: 92px;
  --contactUsGap: 148px;
  --callUsGap: 206px;
  --callUsWidth: 230px;
  --supportMiddle1Gap: 260px;
  --supportMiddle1Height: 328px;
  --termsGap: 588px;
  --supportMiddle2Gap: 644px;
  --privacyGap: 700px;
  --bottomGap: 52px;
  --borderRadius: 0;
}

.supportHomePassV2 {
  --squareFieldDisplay: flex;
  --screenshotWidth: 375px;
  --itemHeight: 50px;
  --squareBorderRadius: 10px;
  --squareFieldWidth: 105px;
  --squareFeldHeight: 102px;
  --topGap: 148px;
  --faqLeft: 17px;
  --contactUsGap: 148px;
  --callUsGap: 148px;
  --callUsLeft: 253px;
  --supportMiddle1Gap: 250px;
  --supportMiddle1Height: 175px;
  --termsGap: 425px;
  --supportMiddle2Gap: 470px;
  --privacyGap: 520px;
  --bottomGap: 234px;
}

.supportWorkPass {
  --screenshotWidth: 375px;
  --itemHeight: 56px;
  --topGap: 134px;
  --contactUsGap: 190px;
  --callUsGap: 246px;
  --callUsWidth: 220px;
  --supportMiddle1Gap: 302px;
  --supportMiddle1Height: 106px;
  --termsGap: 408px;
  --supportMiddle2Gap: 466px;
  --privacyGap: 522px;
  --bottomGap: 234px;
  --borderRadius: 0;
}

/* try */

.supportOverlayTop {
  top: 0;
  height: var(--topGap);
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.faqEnabled {
  top: var(--topGap);
}

.previewFieldDisabled.faqDisabled {
  top: var(--topGap);
  left: var(--faqLeft, initial);
  display: var(--squareFieldDisplay, initial);
  width: var(--squareFieldWidth, var(--screenshotWidth));
  height: var(--squareFeldHeight, var(--itemHeight));
  align-items: center;
  justify-content: center;
  border-radius: var(--squareBorderRadius, 0);
}

.contactUsEnabled {
  top: var(--contactUsGap);
}

.previewFieldDisabled.contactUsDisabled {
  top: var(--contactUsGap);
  display: var(--squareFieldDisplay, initial);
  width: var(--squareFieldWidth, var(--screenshotWidth));
  height: var(--squareFeldHeight, var(--itemHeight));
  align-items: center;
  justify-content: center;
  border-radius: var(--squareBorderRadius, 0);
}

.callUsEnabled {
  position: absolute;
  top: var(--callUsGap);
  left: 0;
  width: 375px;
  height: var(--itemHeight);
  background: white;
  opacity: 0;
  cursor: pointer;
}

.callUsEnabledDN {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: var(--callUsGap);
  right: 20px;
  width: var(--callUsWidth);
  height: var(--itemHeight);
  background-color: white;
  color: var(--titleDarkBlueMagenta);
  cursor: pointer;
}

.callUsEnabledNumber {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(var(--callUsGap) - 44px);
  right: 5px;
  height: 40px;
  padding: 0 5px;
  font-size: 16px;
  line-height: 14px;
  color: #3c396f;
  color: var(--titleDarkBlueMagenta);
  background: white;
  border-bottom: 2px solid var(--primaryBlue);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.callUsEnabledNumberTr {
  position: absolute;
  top: calc(var(--callUsGap) - 6px);
  right: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--primaryBlue);
}

.previewFieldDisabled.callUsDisabled {
  top: var(--callUsGap);
  left: var(--callUsLeft, initial);
  display: var(--squareFieldDisplay, initial);
  width: var(--squareFieldWidth, var(--screenshotWidth));
  height: var(--squareFeldHeight, var(--itemHeight));
  align-items: center;
  justify-content: center;
  border-radius: var(--squareBorderRadius, 0);
}

.supportOverlayMiddle1 {
  top: var(--supportMiddle1Gap);
  height: var(--supportMiddle1Height);
}

.termsEnabled {
  top: var(--termsGap);
}

.termsDisabled {
  top: 528px;
}

.supportOverlayMiddle2 {
  top: var(--supportMiddle2Gap);
  height: var(--itemHeight);
}

.privacyEnabled {
  top: var(--privacyGap);
}

.privacyDisabled {
  top: 656px;
}

.supportOverlayBottom {
  bottom: 0;
  height: var(--bottomGap);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

.override.toggleRoot {
  margin: 4px 10px;
  padding: 6px 0;
  width: 100%;
  cursor: pointer;
}

/* .override.toggleRoot + .formInputFull {
  margin-bottom: 0;
} */

.override.toggleRoot:hover {
  background: var(--popoverToolsBackground);
}

.override.override.toggleAdjustment {
  margin-left: auto;
}

.override.override.toggleAdjustment input[type='checkbox']:checked + span::before {
  background-color: var(--primaryBlue);
}

.textTogglerTitle {
  color: var(--textColor);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--fontWeightNormal);
  font-family: wigrumweb, sans-serif;
  cursor: pointer;
  user-select: none;
}

.yesNoLabel > div:first-child {
  color: var(--textColor);
  font-size: 12px;
  margin: 0 0 0 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-height: 31px;
  width: 100%;
  line-height: 2.17;
  font-weight: var(--fontWeightNormal);
}

.accordionOpen {
  background: rgba(100, 100, 100, 0.1);
}

.override.accordionTitle {
  padding: 4px 0;
  margin: 6px 10px;
  font-weight: 400;
}

.override.accordionTitle:hover {
  background-color: var(--popoverToolsBackground);
}

.override.accordionContent {
  padding: 0;
}

.urlDropdownContainer {
  display: flex;
  align-items: center;
  margin: 0 10px;
  width: 100%;
}

.urlDropdownContainer:hover {
  background: var(--popoverToolsBackground);
}

.urlDropdownContainer .textTogglerTitle {
  cursor: unset;
}

.override.accordionContent .override.toggleRoot,
.override.accordionContent .urlDropdownContainer {
  width: unset;
}

.override.dropdownRoot {
  margin-left: auto;
}

@media only screen and (max-width: 1024px) {
  .root {
    padding: 10px;
    width: calc(100% - 20px);
  }

  .tabsContainer {
    margin-top: 50px;
  }

  .subTitle {
    margin: 15px 40px;
  }

  .labelInfoText {
    margin: 5px 10px;
  }

  .fileSelection {
    width: auto;
  }

  .imagePreview > img {
    width: 275px;
  }

  .imagePreviewIosHome {
    margin-top: -6px;
  }

  .arrowRight {
    padding-left: 10px;
  }

  .arrowLeft {
    padding-right: 10px;
  }

  .imagePreview,
  .imagePreview > img {
    width: 275px;
  }

  /* Preview Common */

  .previewOverlay {
    width: 275px;
  }

  /* Pods Preview */

  .iosPodsHomePass {
    --screenshotWidth: 275px;
    --itemHeight: 46px;
    --topOverlayHeight: 422px;
    --borderRadius: 20px;
    --addPodBottomGap: 127px;
    --buyPodBottomGap: 44px;
  }

  .androidPodsHomePass {
    --screenshotWidth: 275px;
    --itemHeight: 42px;
    --topOverlayHeight: 460px;
    --borderRadius: 0;
    --addPodBottomGap: 95px;
    --buyPodBottomGap: 46px;
  }

  .podsHomePassV2 {
    --screenshotWidth: 275px;
    --itemHeight: 42px;
    --topOverlayHeight: 477px;
    --borderRadius: 0;
    --addPodBottomGap: 75px;
    --buyPodBottomGap: 33px;
  }

  .podsWorkPass {
    --screenshotWidth: 275px;
    --itemHeight: 40px;
    --topOverlayHeight: 398px;
    --borderRadius: 0;
    --buyPodBottomGap: 157px;
  }

  /* Support Preview */

  .iosSupportHomePass {
    --screenshotWidth: 275px;
    --itemHeight: 46px;
    --topGap: 106px;
    --contactUsGap: 152px;
    --callUsGap: 198px;
    --callUsWidth: 130px;
    --supportMiddle1Gap: 244px;
    --supportMiddle1Height: 144px;
    --termsGap: 388px;
    --supportMiddle2Gap: 434px;
    --privacyGap: 482px;
    --bottomGap: 66px;
    --borderRadius: 20px;
  }

  .androidSupportHomePass {
    --screenshotWidth: 275px;
    --itemHeight: 42px;
    --topGap: 66px;
    --contactUsGap: 108px;
    --callUsGap: 150px;
    --callUsWidth: 130px;
    --supportMiddle1Gap: 190px;
    --supportMiddle1Height: 240px;
    --termsGap: 428px;
    --supportMiddle2Gap: 470px;
    --privacyGap: 512px;
    --bottomGap: 42px;
  }

  .supportHomePassV2 {
    --screenshotWidth: 275px;
    --itemHeight: 35px;
    --squareBorderRadius: 10px;
    --squareFieldWidth: 79px;
    --squareFeldHeight: 77px;
    --topGap: 108px;
    --faqLeft: 12px;
    --contactUsGap: 108px;
    --callUsGap: 108px;
    --callUsLeft: 185px;
    --supportMiddle1Gap: 185px;
    --supportMiddle1Height: 120px;
    --termsGap: 305px;
    --supportMiddle2Gap: 344px;
    --privacyGap: 380px;
    --bottomGap: 174px;
  }

  .supportWorkPass {
    --screenshotWidth: 275px;
    --itemHeight: 41px;
    --topGap: 98px;
    --contactUsGap: 139px;
    --callUsGap: 180px;
    --callUsWidth: 140px;
    --supportMiddle1Gap: 222px;
    --supportMiddle1Height: 77px;
    --termsGap: 299px;
    --supportMiddle2Gap: 340px;
    --privacyGap: 381px;
    --bottomGap: 170px;
    --borderRadius: 0;
  }

  .androidHsHomePassV2 {
    --logoTop: 16px;
    --logoLeft: 10px;
    --logoHeight: 15px;
  }

  .androidHsScrolledHomePassV2 {
    display: none;
  }

  .androidPreviewHomePass {
    --logoTop: 27px;
    --logoLeft: 23px;
    --logoHeight: 15px;
  }

  .androidPreviewWorkPass {
    --logoTop: 17px;
    --logoLeft: 8px;
    --logoHeight: 15px;
  }

  .iosHsHomePassV2 {
    --logoTop: 33px;
    --logoLeft: 19px;
    --logoHeight: 15px;
  }

  .iosHsScrolledHomePassV2 {
    display: none;
  }

  .iosPreviewHomePass {
    --logoTop: 50px;
    --logoHeight: 15px;
  }

  .iosPreviewWorkPass {
    --logoTop: 35px;
    --logoLeft: 17px;
    --logoHeight: 15px;
  }
}
