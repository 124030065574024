.paper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
}

.paperTitleWrapper {
  display: flex;
  align-items: center;
  padding-top: 1px;
}

.paperTitle {
  color: var(--textColor);
  margin-left: 10px;
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  height: 30px;
  line-height: 35px;
}

.flex {
  display: flex;
  align-items: center;
}

.content {
  padding-top: 21px;
}

@media only screen and (max-width: 1024px) {
  .paper {
    margin: 10px 10px;
    background-color: var(--backgroundGraySection);
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  }

  .paperTitleWrapper {
    justify-content: space-between;
  }
}
