:root {
  --textColor: #fff;
  --blackColor: #202020;
  --labelColor: #939597;
  --primaryBlue: #6269ff;
  --primaryPurple: #803fce;
  --white: #fff;
  --primaryTallow: #b99948;
  --primaryGray: #e4e4e4;
  --backgroundGray: #111;
  --backgroundGraySection: #171717;
  --backgroundHeader: #212121;
  --backgroundHeaderOverlay: #303030;
  --backgroundSecondaryGray: #f2f3f5;
  --backgroundTertiaryGray: #e1e3e6;
  --backgroundWhite: #fff;
  --borderColor: rgba(222, 222, 222, 0.67); /* #dedede, 0.67 */
  --backgroundPampas: #f9f9f5;
  --backgroundTallow: #a59e86;
  --tooltipBackground: #333;
  --contactPickerBackground: #2f2929;
  --error: #fa1478;
  --sucess: #16e2a8;
  --good500: #17e3ae;
  --warning: #ed1e79;
  --marigold: #ffc500;
  --titleDarkBlueMagenta: #3c396f;
  --inputBackground: transparent;
  --avatarBackground: #4d4d4d;
  --selectBackground: #333;
  --secondaryButtonBackground: #333;
  --activeMenuItemBackground: #2d2d2d;
  --yesNoToggleBackground: #1d1d1d;
  --yesNoToggleDisabled: rgba(222, 222, 222, 0.67);
  --paginationArrowActive: #979797;
  --paginationArrow: rgba(151, 151, 151, 0.5);
  --hoverItem: rgb(71, 71, 71);
  --itemSelected: rgb(146, 146, 146);
  --scrollbarThumb: #6f6f6f;
  --scrollbarThumbHover: #555;
  --errorSecondary: #bf2468;
  --darkenBackground: rgba(0, 0, 0, 0.7);
  --secondaryModalBackground: #333;
  --popoverToolsBackground: rgba(100, 100, 100, 0.3);
  --companySignedContract: #81bb85;
  --companyDemo: #0fdcff;
  --companyHomepass: #f78673;
  --companyWorkpass: #ffc500;
  --selectCompanyTabBackground: rgba(255, 255, 255, 0.05);
  --selectCompanySeparator: #4d4d4d;
  --goodW: #a9fbc6;
  --popoverToolTextNotSelected: #777;
  --secondaryPlumeUi: #8f9397;
  --loginFormBackground: rgba(255, 255, 255, 0.04);
  --loginHeaderBackground: rgba(255, 255, 255, 0.13);
  --colorSolidHot: #6569ff;
  --plumeUiTooltipText: #dee0e2;
  --blackBackground: #000;
  --portalHoverBackground: rgba(100, 100, 100, 0.2);
}
