.select {
  position: relative;
  display: block;
  font-weight: var(--fontWeightMedium);
  font-family: var(--defaultFontFamily);
  width: 100%;
  height: 50px;
  background: var(--selectBackground);
  overflow: hidden;
  border-radius: 28px;
  padding-left: 10px;
}

.select select {
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: var(--selectBackground) none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: var(--white);
  cursor: pointer;
  font-size: 18px;
  font-family: var(--defaultFontFamily);
  font-weight: var(--fontWeightMedium);
  -webkit-appearance: none;
}

.select select::-ms-expand {
  display: none;
}

/* Needed because of rc-tooltip issues with disabled HTML elements */

.select select:disabled {
  pointer-events: none;
}

.selectDisabled {
  cursor: pointer;
  padding-left: 0;
}

/* Arrow */

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 50px;
  background: var(--selectBackground);
  pointer-events: none;
}

.arrowDown {
  position: absolute;
  top: 23px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}
