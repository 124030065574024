.root {
  display: flex;
  font-size: 12px;
  margin-top: 10px;
}

.downloadIcon {
  margin-right: 5px;
}

.exportBtn {
  font-size: 12px;
  padding: 0;
  margin: 0;
  background: transparent;
  color: var(--goodW);
  height: auto;
  font-weight: var(--fontWeightRegular);
  border-radius: 0;
}
