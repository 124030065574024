.root {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  padding: 60px 60px 30px 60px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.labelText {
  margin-left: 10px;
  color: var(--marigold);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 30px;
  font-weight: var(--fontWeightNormal);
}

.override.toggleContainer {
  margin: 10px 0 0 10px;
}

.override.override.toggleAdjustment input[type='checkbox']:checked + span::before {
  background-color: var(--primaryBlue);
}

.inputLabelWidth div:last-of-type {
  width: max-content;
}

.inputHourWrapper {
  display: flex;
}

.hoursLabel {
  margin-left: 10px;
  font-size: 20px;
  margin-top: 30px;
}

.btnLabel {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.expiresInLabel {
  margin: 10px;
  color: var(--marigold);
  font-weight: var(--fontWeightNormal);
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .btnLabel {
    padding: 10px;
    font-weight: var(--fontWeightNormal);
    color: var(--labelColor);
  }

  .subTitle {
    margin: 12px 50px 30px 10px;
  }
}
