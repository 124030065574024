.header {
  height: 94px;
  background: url('~assets/svg/logo-white.svg') no-repeat center;
  background-color: var(--backgroundHeader);
  background-size: 142px 39px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh / var(--vpUnitsFactor, 1) - 130px);
  padding: 40px;
  font-size: 48px;
  color: var(--textColor);
  background-color: var(--backgroundGray);
}

@media only screen and (max-width: 1024px) {
  .title {
    font-size: 24px;
  }
}
