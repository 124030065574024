.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 720px;
  min-height: 320px;
  pointer-events: all;
  padding: 60px 60px 30px 60px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv {
  text-align: center;
}

.actionBtn {
  display: inline-block;
  min-width: 180px;
  padding: 12px 36px;
  margin: 0;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
}

.input input {
  padding: 24px 0 0;
}

.input:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 100%;
  margin-bottom: 20px;
}

.tooltipText {
  display: block;
  max-width: 180px;
}

.copyToClipboard {
  position: absolute;
  right: 8px;
  width: 19px;
  cursor: pointer;
  bottom: 8px;
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.resetPasswordDesc,
.resetPasswordNoticeDesc {
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 22px;
  color: var(--labelColor);
  margin-bottom: 20px;
}

.resetPasswordDesc {
  margin-bottom: 20px;
}

.resetPasswordNoticeDesc {
  color: var(--textColor);
}

.errorWarning {
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--warning);
}

.errorWarning div {
  margin-top: 8px;
}

.errorWarning a {
  display: inline-block;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .subTitle {
    margin: 12px 10px 30px 10px;
  }

  .input {
    height: 60px;
    padding-right: 28px;
  }

  .formInput {
    flex: 0 100%;
  }
}
