.fillSecondaryGrey {
  fill: var(--secondaryPlumeUi);
}

.fillDefault {
  fill: var(--white);
}

.fillActive {
  fill: var(--white);
}

.strokeDefault {
  stroke: var(--white);
}

.strokeDefaultPhone {
  stroke: var(--labelColor);
}

.strokeActive {
  stroke: var(--textColor);
}

.playIconDefault {
  fill: var(--white);
  stroke: #999c9e;
}

.playIconActive {
  fill: #f2f3f5;
  stroke: var(--primaryBlue);
}

.onlineIconActive {
  fill: #16e2a8;
  stroke: #16e2a8;
}

.offlineIconDefault {
  fill: #da197e;
  stroke: #da197e;
}

.dotActive {
  fill: var(--white);
  stroke: var(--white);
}

.dotDefault {
  fill: transparent;
  stroke: var(--white);
}

.playCircled {
  width: 22px;
  height: 22px;
  border: 1px solid #999c9e;
  border-radius: 50%;
  padding-top: 3px;
  padding-left: 3px;
}

.settingsDefaultPadding {
  height: 24px;
  width: 24px;
  padding: 2px;
}

@media only screen and (max-width: 1024px) {
  .fillActive {
    fill: var(--white);
  }

  .strokeActive {
    stroke: var(--textColor);
  }

  .playIconActive {
    fill: var(--white);
    stroke: var(--textColor);
  }
}
