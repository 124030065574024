.root {
  padding: 50px 80px;
}

.headerTitle {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
}

.lastRow .paperContentWrapper {
  padding-bottom: 0;
}

.value {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
}

.block {
  margin-top: 5px;
  display: block;
  text-align: left;
  width: 100%;
}

.link a {
  font-size: 14px;
  font-weight: 500;
  color: #6269ff;
  display: inline;
  margin-left: 10px;
}

.graphWrapper {
  width: 100%;
  height: 100px;
  min-height: 100px;
  padding-top: 20px;
}

.plumeIcon {
  width: 48px;
  margin-right: 12px;
}

.attentionIcon svg {
  margin: 2px 6px 2px 0;
  vertical-align: text-bottom;
}

.wrap {
  display: flex;
  justify-content: space-between;
}

.wrap .paperContentWrapper {
  width: calc(50% - 32px);
}

.filler {
  width: 64px;
  padding: 15px 0;
  border-top: 1px solid #eaeaea;
}

.account {
  margin-top: 10px;
  font-size: 12px;
  color: var(--labelColor);
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

@media only screen and (max-width: 1024px) {
  .root {
    padding: 20px 10px;
  }

  .accountStatusLabel {
    display: none;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .col,
  .colSpan2 {
    width: 100%;
    min-width: 200px;
  }

  .wrap {
    flex-direction: column;
  }

  .wrap .stats:nth-child(1) {
    padding-bottom: 15px;
  }

  .wrap .paperContentWrapper {
    width: 100%;
  }

  .filler {
    display: none;
  }

  .account {
    margin: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
