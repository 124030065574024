.root {
  height: 100%;
  border-radius: 20px;
  z-index: 2;
  margin: 16px;
}

.rootContent {
  border-radius: 20px;
  background: var(--backgroundGray);
  padding: 10px;
}

.title,
.content {
  text-align: center;
  color: var(--textColor);
}

.title {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  margin: 20px auto;
}

.content {
  font-size: 16px;
  line-height: 1.38;
  margin: 0 auto 34px auto;
}

.partnerLink {
  color: var(--goodW);
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .title {
    max-width: 270px;
  }

  .content {
    max-width: 284px;
    margin: 0 auto 20px auto;
  }
}
