.root {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
}

.root input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 18px;
  width: 18px;
  background-color: var(--checkmarkBackgroundColor);
  border: 1px solid var(--checkboxBackground);
  border-radius: 2px;
}

.root input:checked ~ .checkmark {
  background-color: var(--checkboxBackground);
}

.checkmark::after {
  content: '';
  border: solid var(--checkmarkColor);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 5px;
  display: none;
  width: 6px;
  height: 12px;
}

.root input:checked ~ .checkmark::after {
  display: block;
}

.error {
  position: absolute;
  margin-top: 16px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
}
