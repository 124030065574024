.root {
  width: 100%;
  height: 100%;
  min-height: 85px;
  position: relative;
}

.linechartPath {
  stroke-width: 2px;
  fill: none;
}

.linechartAxis {
  stroke: #c6c7c9;
  fill: none;
  stroke-width: 2px;
}

.title {
  font-size: 12px;
  color: #939597;
}

.title svg {
  width: 14px;
  height: 14px;
  vertical-align: sub;
}

.axisLabel {
  fill: #8f9397;
  font-size: 10px;
}

.pathLineActive {
  stroke: #17e3ae;
  fill: none;
  stroke-width: 2px;
}

.pathLineOffline {
  stroke: #999eff;
  fill: none;
  stroke-width: 2px;
}

.circle {
  cursor: pointer;
  opacity: 0;
}

.circle:hover {
  cursor: pointer;
  opacity: 1;
}

.displayNone {
  display: none;
}

.displayHidden {
  visibility: hidden;
}

.displayInline {
  display: inline;
}

.toolTip {
  position: absolute;
  font-size: 10px;
  color: var(--titleDarkBlueMagenta);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.34);
  background-color: #1d2120;
  border: 1px solid #8f9397;
  padding: 5px 5px 3px 4px;
  margin-left: 2px;
  backdrop-filter: blur(30px);
  z-index: 2;
}

.displayFlex {
  display: flex;
}

.toolTipValue {
  color: #fff;
  line-height: 16px;
  padding-left: 10px;
}

.override.override.onlineStatus {
  font-size: 10px;
  color: var(--sucess);
}

.override.override.offlineStatus {
  font-size: 10px;
  color: #999eff;
}

.override.override.offlineIndicator {
  background: #999eff;
}
