.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  pointer-events: all;
  padding: 40px 40px 20px 40px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.addNewBtnDiv {
  margin: 10px 0;
  text-align: center;
}

.addNewBtn {
  height: 38px;
  width: 232px;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 0;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
  min-width: 584px;
}

.listLabel {
  color: var(--textColor);
  font-size: 12px;
  margin: 0 0 0 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 31px;
  width: 100%;
  line-height: 2.17;
  font-weight: var(--fontWeightNormal);
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.rncServerError {
  margin: -10px 10px 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.contactPicker {
  padding: 0 10px;
  width: 100%;
}

.overlay {
  position: absolute;
  background: var(--contactPickerBackground);
  z-index: 1;
}

.usersWrapper {
  min-width: 584px;
  height: 150px;
  margin-top: 20px;
  overflow-y: scroll;
}

.userWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 5px 30px 5px 30px;
  cursor: pointer;
}

.userWrapperSelecting:hover {
  background-color: var(--hoverItem);
}

.userWrapperSelected {
  background-color: var(--itemSelected);
}

.userInfoWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.userInfoColumn {
  flex: 1 1 80%;
}

.logo {
  width: 65px;
}

.logoCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  background: var(--primaryTallow);
}

.logoText {
  font-size: 16px;
  color: var(--white);
  line-height: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.titleSearch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInput {
  min-width: 584px;
  color: var(--textColor);
  border: 0 solid var(--primaryGray);
  border-bottom-width: 2px;
  display: block;
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  font-family: var(--defaultFontFamily);
  transition: 0.2s ease border-color;
  padding: 0 15px 0 10px;
  width: 100%;
  background-color: var(--inputBackground);
  outline: transparent;
}

.valError {
  margin: 0 10px;
  padding-top: 3px;
  color: var(--warning);
  font-size: 12px;
}

.arrowDown {
  position: absolute;
  right: 55px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .subTitle {
    min-width: calc(80vw / var(--vpUnitsFactor, 1));
    margin: 12px 10px 30px 10px;
  }

  .overlay {
    width: calc(100% - 40px);
  }

  .rncServerError {
    margin: -30px 10px 0;
  }

  .usersWrapper,
  .searchInput {
    min-width: 80%;
  }

  .arrowDown {
    right: 25px;
  }
}

@media only screen and (max-width: 454px) {
  .userInfoColumn {
    flex: 1 1 40%;
  }
}
