.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--backgroundGray);
  height: 100%;
}

.title {
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 3px;
  text-transform: uppercase;
  max-width: 124px;
  color: var(--textColor);
  margin: 0 auto;
}

.text {
  font-weight: 500;
  font-size: 54px;
  line-height: 72px;
  text-align: center;
  text-transform: capitalize;
  margin-top: 33px;
}

.separator {
  height: 2px;
  margin: 32px auto 0;
  background: #6269ff;
  max-width: 338px;
}

.actionBtn {
  margin: 31px auto 0;
  margin-top: 23px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 14.5036px;
  line-height: 22px;
  padding: 8px 24px 6px;
  max-width: 206px;
  min-height: 40px;
}

.listWrapper {
  margin-top: 33px;
}

.list {
  list-style: none;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 0 auto;
  margin-top: 33px;
  max-width: 768px;
  width: 100%;
}

.list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.errorKey {
  width: 150px;
  text-align: left;
}

.errorValue {
  display: inline-block;
  text-align: left;
  flex: 1 0;
}

.imageTop,
.imageBottom {
  position: absolute;
  width: auto;
  height: auto;
  filter: invert(1);
  z-index: -1;
}

.imageTop {
  top: -62%;
  width: 57%;
}

.imageBottom {
  bottom: -47%;
  right: 0;
  width: 35.5%;
  z-index: -2;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .root {
    padding-bottom: 120px;
  }

  .title {
    margin: 120px auto 0;
  }

  .text {
    font-size: 36px;
    line-height: 36px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageTop {
    width: 80%;
  }

  .imageBottom {
    bottom: -49%;
    width: 50%;
  }

  .list {
    max-width: 90%;
  }

  .list li {
    flex-direction: column;
  }
}
