.errorScreen {
  position: absolute;
  text-align: center;
  border-radius: 6px;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  left: 0;
  right: 0;
  z-index: 1001;
}

.errorScreen h1 {
  font-size: 36px;
}

.errorScreen h2 {
  font-size: 20px;
  line-height: 24px;
  color: var(--textColor);
  white-space: pre;
}

.primaryBtn {
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  font-family: var(--defaultFontFamily);
  display: inline-block;
  width: auto;
  height: 44px;
  padding: 12px 36px;
  font-size: 18px;
  color: var(--white);
  border: solid 2px var(--primaryBlue);
  background: var(--primaryBlue);
  border-radius: 24px;
  cursor: pointer;
}

.secondaryBtn {
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  font-family: var(--defaultFontFamily);
  display: inline-block;
  width: auto;
  height: 44px;
  padding: 12px 36px;
  font-size: 18px;
  color: var(--primaryBlue);
  border: solid 2px var(--primaryBlue);
  border-radius: 24px;
  cursor: pointer;
}

.primaryBtn + .secondaryBtn {
  margin-left: 8px;
}
