.root {
  padding: 50px 80px;
}

.section {
  margin-bottom: 80px;
}

.header {
  margin-bottom: 10px;
}

.headerButtons {
  display: flex;
  align-items: center;
}

.headerFlowButtons {
  display: flex;
  margin-left: auto;
}

.buttonMargins {
  margin: 0 5px;
}

.sequenceSeparator {
  height: 20px;
}

.override.sequenceRoot {
  margin-bottom: 26px;
  margin-left: 64px;
  width: calc(100% - 64px - 34px);
}

.arrayFieldContainer {
  display: flex;
  align-items: center;
}

.arrayInput {
  width: 100%;
}

.arrayInput > label {
  display: none;
}

.arrayInput > input {
  height: auto;
  padding: 0;
  font-size: 16px;
  font-weight: initial;
}

.contentWrapper {
  display: flex;
}

.override.cardSize {
  width: 63%;
  width: clamp(10px, 63%, 935px);
}

.override.additional {
  width: 30%;
  width: clamp(10px, 30%, 450px);
  margin-left: auto;
  height: 300px;
}

.infoBoxTitle {
  margin-bottom: 20px;
}

.copyIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.override.copyField,
.override.copyField input,
.override.copyField label {
  cursor: default;
}

.downloadMetadata {
  cursor: pointer;
  color: var(--primaryBlue);
}

.knowledgeTiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 80px;
}

.arrowLeftSize {
  width: 20px;
  height: 20px;
  color: #c6c6c6;
}

.backText {
  color: #c6c6c6;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.knowledgeTiles > * {
  max-width: 380px;
}

.headerTitle {
  font-size: 28px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  width: 66%;
  font-family: '-OC Rey', serif;
}

.colorIncomplete {
  --statusColor: var(--marigold);
}

.colorComplete {
  --statusColor: var(--good500);
}

.stepStatus {
  color: var(--statusColor);
}

.statusDot circle {
  fill: var(--statusColor);
  stroke: var(--statusColor);
}

.inputSeparator {
  height: 10px;
}

.inputInfoWrapper {
  display: flex;
}

.inputInfoWidth {
  width: 100%;
}

.infoButtonRoot {
  height: 100%;
}

.override.infoButtonModifications {
  background: transparent;
}

.override.portalTextAreaModifications > textarea {
  background-color: var(--backgroundGray);
  padding: 0 0 9px 0;
  caret-color: var(--colorSolidHot);
  height: 340px;
  font-family: monospace;
  font-size: 14px;
  letter-spacing: -0.5px;
  border-color: var(--secondaryPlumeUi);
}

.override.portalTextAreaModifications > label {
  font-size: 16px;
  color: var(--textColor);
  text-transform: initial;
  letter-spacing: initial;
  padding: initial;
  font-weight: initial;
  position: initial;
}

.override.portalTextAreaModifications.errorText > label {
  color: var(--error);
}

.minusRounded {
  cursor: pointer;
  margin-right: 5px;
}

.setTeamsWarning {
  color: var(--marigold);
  font-size: 14px;
}

.cardImage {
  background-size: 40%;
  background-position: center;
  background-color: #1c1c1c;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

.infoButtonUrl {
  width: 28px;
  color: var(--labelColor);
  margin-left: 10px;
}

.partnerIdentificationWidth {
  width: 300px;
}

.partnerIdentificationColon {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 12px;
}

.override.override.minusSize {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.xmlIdpError {
  margin: 10px 0;
  color: var(--error);
}

.noPadding.noPadding.noPadding {
  padding: 0;
}

.fitContent.fitContent.fitContent {
  width: fit-content;
}

.multiPartnerDropdownMinWidth.multiPartnerDropdownMinWidth.multiPartnerDropdownMinWidth {
  text-align: left;
  min-width: 250px;
}

.dropdownLabelWarning {
  color: var(--error);
}

:global(.Card).cardMargins :global(.CollapseCard__content--open) {
  max-height: 10000px;
}

@media only screen and (max-width: 1500px) {
  .override.sequenceRoot {
    margin-bottom: 60px;
    margin-left: 24px;
    width: calc(100% - 46px);
  }

  .override.sequenceLabel {
    white-space: initial;
  }

  .multiPartnerDropdownMinWidth.multiPartnerDropdownMinWidth.multiPartnerDropdownMinWidth {
    text-align: left;
    min-width: unset;
    width: 200px;
    letter-spacing: -1px;
  }

  .partnerIdentificationWidth {
    width: 200px;
  }
}

@media only screen and (max-width: 1024px) {
  .root {
    padding: 25px 15px;
  }

  .knowledgeTiles {
    display: flex;
    justify-content: center;
  }

  .contentWrapper {
    flex-direction: column;
  }

  .override.cardSize {
    width: 100%;
  }

  .override.additional {
    width: 100%;
    margin-left: 0;
  }
}
