.footer {
  position: relative;
  background-color: var(--backgroundGray);
  height: 36px;
  color: #999c9e;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 18px;
}

.footer::before {
  position: absolute;
  content: '';
  background: var(--white);
  opacity: 0.1;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}

.partialLogo {
  width: 24px;
  height: 24px;
  float: left;
}

.text {
  float: right;
}

.versionWrapper {
  display: inline;
}

@media only screen and (max-width: 1024px) {
  .portalName {
    display: none;
  }
}
