.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  pointer-events: all;
  padding: 40px 40px 20px 40px;
  overflow-y: auto;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
  min-width: 584px;
}

.override.override.dropdownBackground {
  background: var(--backgroundGray);
  backdrop-filter: none;
}

.disclaimer {
  font-size: 18px;
  margin: 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
  min-width: 584px;
  color: var(--primaryBlue);
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.actionBtnContainer {
  display: flex;
  justify-content: center;
}

.actionBtnWrapper {
  margin: 10px;
}

.actionBtn {
  width: 150px;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 0;
}

.deleteBtn {
  width: 150px;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 0;
  background: none;
  border: 2px solid var(--primaryBlue);
  color: var(--primaryBlue);
}

.copyToClipboard {
  position: absolute;
  right: 8px;
  width: 19px;
  cursor: pointer;
  bottom: 8px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 100%;
  margin-bottom: 20px;
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
}

.tableLabel {
  padding: 10px 15px 10px 0;
}

.tableValue {
  padding: 10px 0;
  word-break: break-all;
}

.showLineBreaks {
  white-space: pre-line;
}

.tooltipText {
  display: block;
  white-space: pre;
}

.copyableData {
  cursor: pointer;
  padding: 16px 0;
}

.label {
  padding-bottom: 5px;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

.m2mSuccess {
  margin: 0 auto;
  display: table;
  color: var(--sucess);
}

.tableContainer {
  max-height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.tableContainer::-webkit-scrollbar {
  width: 7px;
}

.tableContainer::-webkit-scrollbar-track {
  background: var(--backgroundGray);
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbarThumbHover);
}
