.root {
  background-color: var(--backgroundGray);
  padding-bottom: 40px;
  min-height: 100%;
  width: 100%;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  padding: 10px;
  cursor: pointer;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
  max-width: 700px;
}

.logoutIcon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.logoutText {
  font-size: 16px;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
  height: 30px;
  line-height: 35px;
  margin-left: 24px;
}

.titleSearch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInput {
  font-size: 24px;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
  padding: 0 40px;
  outline: transparent;
  font-family: inherit;
  margin: 40px 20px 20px 20px;
  width: 648px;
  height: 67px;
  background: var(--selectCompanySeparator);
  border-radius: 60px;
  border: 0 solid transparent;
}

.companyWrapper {
  display: flex;
  width: 100%;
  max-width: 1260px;
  margin: -16px auto;
  flex-wrap: wrap;
  justify-content: center;
}

.companyCol {
  width: calc(100% / 3);
  padding: 16px;
  cursor: pointer;
}

.companyColDisabled {
  opacity: 0.5;
  cursor: default;
}

.checkBox {
  display: flex;
}

.checkBox span::after {
  border: solid var(--backgroundGray);
  border-width: 0 2px 2px 0;
}

.checkBox span {
  background-color: transparent;
}

.checkBox.checkBoxTrialOver span {
  border-color: var(--warning);
}

.checkBox.checkBoxSigned span {
  border-color: var(--companySignedContract);
}

.checkBox.checkBoxDemo span {
  border-color: var(--companyDemo);
}

.checkBox.checkBoxWorkpass span {
  border-color: var(--companyWorkpass);
}

.checkBox.checkBoxHomepass span {
  border-color: var(--white);
}

.checkBox.checkBoxTrialOver input:checked ~ span {
  background-color: var(--warning);
}

.checkBox.checkBoxSigned input:checked ~ span {
  background-color: var(--companySignedContract);
}

.checkBox.checkBoxDemo input:checked ~ span {
  background-color: var(--companyDemo);
}

.checkBox.checkBoxWorkpass input:checked ~ span {
  background-color: var(--companyWorkpass);
}

.checkBox.checkBoxHomepass input:checked ~ span {
  background-color: var(--white);
}

.checkBox label {
  width: 18px;
  height: 18px;
}

.filterBy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: 84px;
  margin: 20px auto 40px auto;
  background: var(--selectCompanyTabBackground);
  border-radius: 240px;
}

.filterGroup {
  padding: 15px 20px 15px 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: 0 10px;
  flex-direction: column;
  align-items: center;
}

.checkboxesWrapper {
  display: flex;
  margin-bottom: 10px;
}

.filterGroupSeparator {
  width: 48px;
  border: 1px solid var(--selectCompanySeparator);
  transform: rotate(90deg);
}

.filterLabel {
  color: var(--borderColor);
  margin-bottom: 10px;
}

.btnLabel {
  margin-right: 10px;
}

.selectContainer {
  width: 25%;
  margin-left: 10px;
}

.company {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 16px;
  background-color: var(--selectCompanyTabBackground);
  line-height: 21px;
  padding: 16px;
}

.companyLogo {
  margin-right: 25px;
}

.companyLogoText.companyTrialOver {
  background: var(--warning);
}

.companyLogoText.companyInTrial {
  background: var(--primaryBlue);
}

.companyLogoText.companyContractSigned {
  background: var(--companySignedContract);
}

.companyLogoText.companyDemo {
  background: var(--companyDemo);
}

.companyLogoText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #414141;
  border-radius: 50%;
  font-size: 20px;
  color: var(--white);
  font-weight: var(--fontWeightMedium);
}

.companyText {
  display: flex;
  flex-direction: column;
}

.companyName {
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  font-size: 20px;
  width: 100%;
}

.companyProducts {
  margin-top: 4px;
  color: var(--textColor);
  font-size: 14px;
}

.companyProductsHomepass {
  color: var(--companyHomepass);
}

.companyProductsWorkpass {
  color: var(--companyWorkpass);
}

.companyInfo {
  color: var(--labelColor);
  font-size: 14px;
}

.companyMeta {
  color: var(--textColor);
  font-size: 14px;
}

.companyLogoImage img {
  width: 50px;
  height: 50px;
  border: 1px solid var(--primaryGray);
  border-radius: 50%;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: var(--textColor);
  margin: 40px 0 20px 0;
}

@media only screen and (max-width: 1024px) {
  .title {
    margin: 20px;
  }

  .filterBy {
    width: 90%;
    width: clamp(0%, 90%, 600px);
    flex-wrap: wrap;
    height: auto;
    border-radius: 20px;
  }

  .filterGroupSeparator {
    flex-basis: 100%;
    border: 0;
  }

  .company {
    width: 300px;
  }

  .companyWrapper {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .companyName {
    font-size: 16px;
  }

  .companyLogoText {
    width: 35px;
    height: 35px;
    padding: 10px 0;
    font-size: 16px;
  }
}
