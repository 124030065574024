.select {
  background-color: var(--white);
  border-radius: 27px;
  width: 180px;
  height: 36px;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
}

.select select {
  background-color: var(--white);
  font-size: 14px;
}

.select::after {
  background: var(--white);
  pointer-events: none;
  line-height: 3.8;
}

.arrow {
  background: var(--white);
  height: 36px;
}

.arrowDown {
  top: 18px;
}
