.root {
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  margin: 15px;
  overflow: hidden;
  width: 312px;
  background: var(--backgroundGray);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  min-height: 444px;
}

.root .banner {
  position: absolute;
  top: 14px;
  right: -22px;
  z-index: auto;
  min-width: 20px;
  height: 22px;
  padding: 0 32px;
  color: var(--white);
  font-size: 12px;
  line-height: 24px;
  white-space: nowrap;
  text-align: center;
  background: var(--primaryBlue);
  transform: rotate(45deg);
  text-transform: uppercase;
  user-select: none;
}

.image {
  height: 178px;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 12px 12px 0 0;
  background-color: var(--backgroundGray);
}

.content {
  padding: 32px 32px 12px;
  text-align: center;
}

.title {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.titleBottomMargin {
  margin-bottom: 16px;
}

.title > span {
  margin: auto;
  display: inline-block;
  font-size: 24px;
}

.trialSection {
  font-size: 14px;
  font-weight: 100;
  color: var(--marigold);
}

.description {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 12px;
}

.extra {
  margin-top: 24px;
}

.button {
  margin-top: auto;
  margin-bottom: 44px;
  text-align: center;
}

.buttonContainer {
  margin: auto;
  display: inline-block;
}

.configAccessCta {
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 7px;
  background: var(--backgroundGray);
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.rootClassName {
  position: relative;
  background-color: transparent;
  min-height: 34px;
}

.loaderClassName {
  margin: 0 auto;
  transform: translateZ(0) scale(0.25);
}
