.root {
  position: absolute;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  pointer-events: all;
  padding: 60px 60px 30px 60px;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv {
  margin-top: 20px;
  text-align: center;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.checked {
  position: absolute;
  top: -12px;
  right: 15px;
}

.userRoles,
.userTools {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 0 10px;
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  background-color: #f2f3f5;
  text-align: center;
}

.input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
}

.input input {
  padding: 24px 0 0;
}

.inputDisabled input {
  color: var(--labelColor);
}

.input:focus {
  border-color: var(--primaryBlue);
  outline: 0;
}

.marginLeft {
  margin-left: 260px;
}

.deleteBtn {
  text-align: center;
}

.deleteBtn button {
  margin-bottom: 0;
  background: none;
  color: var(--primaryBlue);
  font-size: 16px;
  padding: 12px;
  font-weight: var(--fontWeightRegular);
}

.modalHidden {
  visibility: hidden;
}

.btnLabel {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.languageSelectLabel {
  color: var(--textColor);
  font-size: 12px;
  margin: 0 0 0 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 31px;
  width: 100%;
  line-height: 2.17;
  font-weight: var(--fontWeightNormal);
}

.toolsWrapper {
  margin: 0 10px;
}

.checkbox {
  width: 30px;
  display: inline-block;
}

.toolText {
  margin-top: -10px;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--fontWeightMedium);
}

.toolsCb {
  margin-bottom: 10px;
  display: inline-flex;
}

.toolsCbDisabled span {
  border: 1px solid var(--labelColor);
}

.toolsText {
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 22px;
  color: var(--labelColor);
}

.roleSelect {
  display: inline-block;
  width: calc(50% - 5px);
}

.roleSelectSingle {
  margin-top: 10px;
  padding-left: 5px;
  display: inline-block;
  width: calc(50% - 5px);
}

.formInputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.formInput {
  flex: 0 calc(50% - 5px);
}

.releaseNotesFormInput {
  flex: 0 100%;
  width: 500px;
}

.portalLanguageSelect {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.portalLanguageFormInput {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 0 10px;
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  background-color: var(--selectBackground);
  text-align: center;
}

.serverError {
  margin: 0 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.rncServerError {
  margin: -10px 10px 10px;
  line-height: 1.33;
  font-size: 12px;
  color: var(--error);
  padding-top: 6px;
}

.toolsWarning {
  margin: 0 10px;
  font-size: 14px;
  color: var(--warning);
}

.configItem {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .btnLabel {
    padding: 10px;
    font-weight: var(--fontWeightNormal);
    color: var(--labelColor);
  }

  .subTitle {
    margin: 12px 10px 30px 10px;
  }

  .roleSelect {
    display: inline-block;
    width: 100%;
  }

  .formInputs {
    margin-bottom: 30px;
  }

  .rncServerError {
    margin: -30px 10px 0;
  }

  .formInput,
  .releaseNotesFormInput {
    flex: 0 100%;
  }

  .toolsCb {
    display: inline-flex;
    width: 50%;
  }
}
