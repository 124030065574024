.root {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  padding: 60px 60px 30px 60px;
  min-height: 300px;
  max-height: calc(95vh / var(--vpUnitsFactor, 1));
  max-width: calc(95vw / var(--vpUnitsFactor, 1));
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.btnDiv {
  margin-top: 20px;
  display: flex;
}

.sendInviteBtn {
  width: 232px;
  border-radius: 25px;
  display: inline-block;
}

.override.override.minusSize {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.nodeInputWrapper {
  display: grid;
  /* stylelint-disable-next-line */
  grid-template-columns: 400px 400px 400px auto;
  gap: 10px;
}

.subTitle {
  font-size: 18px;
  margin: 0 10px 25px 10px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.labelText {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.verticalSpace {
  width: 100%;
  height: 20px;
}

.btnLabel {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px;
  font-weight: var(--fontWeightNormal);
  color: var(--labelColor);
}

.serverError {
  line-height: 1.33;
  color: var(--error);
  margin-top: 10px;
  white-space: pre-line;
}

.loaderToFront {
  z-index: 100;
}

.separator10px {
  height: 10px;
}

.marginBottom10px {
  margin-bottom: 10px;
}

.nodeUploadPreviewRoot {
  position: absolute;
  border-radius: 16px;
  background: var(--backgroundGray);
  top: 50%;
  left: 50%;
  width: calc(100% - 40px);
  transform: translate(-50%, -50%);
  padding: 10px;
  overflow-y: auto;
  height: calc(100vh / var(--vpUnitsFactor, 1) - 40px);
}

.previewSubtitle {
  width: max-content;
  position: sticky;
  left: calc(50% - 50px);
  font-size: 18px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.previewCloseButton {
  position: sticky;
  left: calc(100% - 40px);
  cursor: pointer;
  width: max-content;
  transform: translateY(20px);
}

.nodeUploadPreviewTableRoot.nodeUploadPreviewTableRoot.nodeUploadPreviewTableRoot {
  line-height: 26px;
}

.nodeUploadPreviewTableRoot.nodeUploadPreviewTableRoot.nodeUploadPreviewTableRoot :global(.Table__heading--sticky) {
  top: -10px;
}

@media only screen and (max-width: 1400px) {
  .nodeInputWrapper {
    /* stylelint-disable-next-line */
    grid-template-columns: auto auto auto 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .root {
    position: absolute;
    border-radius: 16px;
    background: var(--backgroundGray);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .btnLabel {
    padding: 10px;
    font-weight: var(--fontWeightNormal);
    color: var(--labelColor);
  }

  .subTitle {
    margin: 12px 50px 30px 10px;
  }
}
